@import '../../../shared/styles/colors.scss';

.ant-modal-title {
    font-weight: 600 !important;
    font-size: 15px !important;
    color: $grey-1000  !important;
}

.ant-modal-footer {

    .save-btn,
    .save-btn:hover {
        color: $white-000  !important;
        background-color: $orange-700  !important;
        border-color: $orange-700  !important;
    }

    .cancel-btn,
    .cancel-btn:hover {
        color: $orange-700  !important;
        border: 1px solid $orange-700;
        border-color: $orange-700  !important;
    }
}