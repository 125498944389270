@import '../../../../../../shared/styles/colors.scss';

.add-medication-set-container {
  padding: 30px;

  &__body {
    background: #fff;
    border-radius: 16px;
    padding: 20px;

    h4 {
      color: $orange-700;
      font-weight: 500;
      font-size: 16px;
      margin: 0px;
      margin-bottom: 10px;
    }

    .input-field-wrapper {
      .length {
        text-align: end;
        color: $grey-1000;
        font-size: 15px;
        font-weight: 300;
      }
    }
    .medication-set-dropdown {
      background-color: #f4f4f4;
      border-radius: 16px;
      width: 100%;

      .ant-select-selector {
        border: none;
        min-height: 48px;
        height: auto;
        background-color: transparent;
        align-items: center;
      }
    }
    .medication-three-fields-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 0px 40px;
    }

    .medication-supply-products-container {
      margin: 0 0 10px 0;
      border: 1px solid #ff6f33;
      border-radius: 20px;
      padding: 20px;
    }

    .medication-multi-fields-wrapper {
      display: grid;
      grid-template-columns: 3fr 3fr 2fr 1fr 0.8fr 0.2fr;
      // justify-items: center;
      align-items: center;
      gap: 0px 40px;

      .default_checkbox {
        display: grid;
        gap: 10px;
        place-items: center;
      }

      .item_action {
        display: flex;
        justify-content: flex-end;
      }
    }

    .add-new-medication-supply-btn {
      margin: 10px 0;
      cursor: pointer;
      width: fit-content;
      svg {
        margin-top: 10px;
      }
    }

    .add-new-product-btn {
      cursor: pointer;
      width: fit-content;
    }

    .medication-set-d-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;

     
      .ant-picker {
        width: 100%;
        height: 44px;
        background-color: $grey-100;
        border: none;
        border-radius: 16px;
      }
      .add-cat-label {
        display: block;
      }
    }

    .tri-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 10px;
      margin-top: 15px;
    }

    .action-btn-wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 25px;
    }
  }
}

.added-medication-set-container {
  &__body {
    label {
      display: block;
      margin: 10px 0px;
    }
  }
}

.add-bio-marker-dialog {
  width: 900px !important;

  .ant-checkbox-inner {
    width: 24px;
    height: 24px;
  }

  .bio-marker-checkbox {
    display: flex;
  }

  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-checkbox-checked:hover,
  .ant-checkbox-inner:hover {
    background: $grey-1000 !important;
    border-color: $grey-1000 !important;
  }
}
.mt-10 {
  margin-top: 15px;
}

.range-wrapper {
  .range-flex {
    display: flex;
    gap: 15px;
    input {
      width: 228px;
    }
  }
}
.range-d-flex {
  display: flex;
  justify-content: space-between;
}

.medication-action-btn {
  display: flex;
  justify-content: flex-end;
}
