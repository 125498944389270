@import '../../../shared/styles/colors.scss';

.menuItem {
    padding: 16px;
    text-align: center;
    background: $grey-000;
    color: $grey-800;
    img{
        display: block;
        margin: auto;
    }
}

.active {
    display: block;
    background-color: $orange-100;
    color: $orange-700;
    border-right: 1px solid $orange-700;
}
.icon{
    display: flex;
    position: relative;
    .counter{
        top:-10px;
        position: absolute;
        right: 0;
        background-color: $alert-red;
        width: 20px;
        height: 20px;
        color: white;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        font-weight: 700;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: center;
    }
}