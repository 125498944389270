@import '../../../../shared/styles/colors.scss';

.add-new-specialty-container{
    h1{
        margin: 0;
    }
    &__wrapper{
        padding-left: 20px;
    }
    .section{
        padding: 20px 0;
        border-bottom: 1px solid #e0e0e0;
        .provider-wrapper{
            h3{
                margin: 8px 0;
                color: #393B3D;
                font-family: Nunito;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px; /* 137.5% */
            }
        }
        .ant-form-item{
            margin-bottom: 0px !important;
        }

        .procedure-outer{
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
        .procedure-sub-container{
            border: 1px solid #e0e0e0;
            border-radius: 15px;
            padding: 15px;
            display: flex;
            width: 97%;
            margin-bottom: 20px;
            margin-left: 10px !important;
            .ant-switch{
                margin-left: auto;
                height: 30px;
                border: none;
                .ant-switch-handle{
                    height: 25px;
                }
            }
            .active{
                background-color: #cbf38a;
            }
            .inactive{
                background-color: #e0e0e0;
            }
            .ant-switch-inner-checked{
                color: #cbf38a;
            }
            .ant-switch-inner-unchecked{
                color: #e0e0e0;
            }
            .procedure-item{
                display: flex;
                width: 100%;
            }
            .items-outer{
                padding: 10px;
                width: 100%;
                padding-right: 0px;
            }
            .proced-dollar{
                display: flex;
                align-items: center;
                margin-left: auto;
                span{
                    margin-right: 10px;
                }
            }
            .section{
                border-bottom: 1px solid #e0e0e0;
                font-size: 16px !important;
                font-weight: 700;
                padding-left: 0px;
                display: flex;
                .ant-select-selector{
                    font-size: 17px;
                    background-color: #F4F4F4;
                    height: 45px;
                    border: none;
                }
                .ant-select-selection-item{
                    align-items: center;
                    display: flex;
                    font-size: 16px;
                }
                .sub-item .ant-form-item-control-input-content{
                    display: flex;
                    align-items: center;
                }
            }
            .last-section{
                font-size: 16px !important;
                font-weight: 700;
                padding-left: 0px;
                textarea{
                    width: 100%;
                }
            }

            .align-right{
                margin-left: auto;
            }
        }
        .radio-discount-label{
            padding-left: 20px;
        }

        .member-sub-container{
            margin-left: 25px;
            display: flex;
            align-items: center;
            gap: 10px;
            .ant-input{
                width: auto;
                display: flex;
                margin-bottom: 5px;
            }
            svg{
                cursor: pointer;
            }
            
            .member-auto-search{
                .ant-select-selector{
                    height: 48px;
                    padding: 0 11px;
                    border-radius: 18px;
                    background-color: #F4F4F4;
                    border: none;

                    .ant-select-selection-search-input{
                        margin-top: 8px;
                    }
                }
            }
        }

        .sub-container{
            margin-left: 25px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .ant-input{
                width: auto;
                display: flex;
            }
            svg{
                cursor: pointer;
            }
        }
        .sub-container-region{
            margin-left: 25px;
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            width: 100%;
            justify-content: space-between;
            .ant-input{
                width: auto;
                display: flex;
            }
            svg{
                cursor: pointer;
            }
        }

        .add-icon{
            width: fit-content;
            align-items: center;
            display: flex;
            margin-top: 10px;
            margin-left: 25px;
            cursor: pointer;

            label{
                margin-left: 5px;
                cursor: pointer;
            }
        }

        .radio-group{
            gap: 8;
            display:flex;
            flex-direction: column;
            justify-content: space-between,
          }

          .ant-input{
            font-size: 17px;
            border-radius: 16px;
            padding: 11px 16px;
            background-color: #F4F4F4;
            border: 0;
            width: 100%;
            display: block;
            }

            .sub-item .ant-form-item-control-input-content{
                display: flex;
                align-items: center;
            }

            .discount-type-container{
                display: flex;
                margin-top: 10px;
                margin-bottom: 10px;

                .discount-type-sub-container{
                    display: flex;
                    align-items: center;

                    .drp-discount{
                        margin-left: 15px;
                    }

                    .ant-select-selector{
                        font-size: 17px;
                        background-color: #F4F4F4;
                        height: 50px;
                    }
                }
            }
    }

    .ant-disabled{
        input{
        cursor: no-drop;
        }
        .label{
            color: #b7b7b7 !important;
        }
    }
    
    .promo-error{
        color: #ff4d4f;
        margin-left: 25px;
    }

    .deep-link{
        color: #AD3E06;
        text-decoration: underline;
        font-size: 16px;
        font-weight: 600;
        display: flex;
        align-items: center;
        svg{
            margin-left: 15px;
            cursor: pointer;
        }
    }
    .deep-link-copy{
        color: #148051;
        font-size: 12px;
        display: flex;
        align-items: center;
    }

    .promo-link{
        display: flex;
        align-items: center;
        label{
            margin-top: 5px;
            margin-left: 5px;
        }
    }

    #discountType_help{
        position: absolute;
        width: 300px;
        margin-top: 18px;
    }

    .ant-select-selection-item{
        font-size: 17px;
        display: flex;
        align-items: center;
    }

    .btn-promo-outer{
        width: 320px;
        justify-content: space-between;
        display: flex;
        button{
            margin-left: auto;
        }
    }

    .green-button{
        background-color: #148051;
        border-color: #148051;
        color: #fff;
        padding: 8px 20px;
        height: unset;
        font-size: 17px;
        font-weight: 600;
        border-radius: 8px;
        
    }
    .green-button.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover{
        background-color: #148051 !important;
        border-color: #148051 !important;
    }

    .check-label-promo{
        display: flex;
        margin-bottom: 10px;
    }

    .seprator-or{
        display: flex;
        margin: 10px;
    }

    .promo-image{
        margin-top: 10px;
    }

    .text-length{
        text-align: right;
    }
}

.program-listing{
    &__header1 {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        span {
            color: $orange-700;
            font-size: 15px;
            font-weight: 600;
            margin: 0px;
            text-decoration: underline;
            cursor: pointer;
        }

        svg {
            margin: 0px 10px;
        }

        h4 {
            color: $grey-1000;
            font-size: 15px;
            font-weight: 500;
            margin: 0px;
        }
    }
}

.search-label{
    color: $grey-1000;
    font-size: 15px;
    font-weight: 500;
    margin-left: 5px;
}

.promo-deep-link{
    padding: 0px !important;
    .promo-header{
        font-size: 16px !important;
        font-weight: 700;
        color: #AD3E06;
    }
    .no-border{
        border-bottom: none;
        padding: 0px;
    }
    .add-new-specialty-container__wrapper{
        padding: 0px !important;
    }
    .check-label-promo {
        margin-top: 10px;
    }
    .btn-promo-outer {
        width: 280px;
        justify-content: space-between;
        display: flex;
        margin-left: auto;
    }
    .deep-link-item{
        margin-bottom: 10px;
    }

    .promo-alert{
        display: flex;
        align-items: center;
        background-color: #FFE1D6;
        padding: 10px;
        border-radius: 10px;
        margin-bottom: 10px;
        span{
            font-family: Nunito;
            font-size: 17px;
            font-weight: 600;
            line-height: 22px;
            margin-left: 10px;
            margin-top: 5px;
        }
    }
}

.program-member-filter{
    display: flex;
    width: 800px;
    align-items: center;
    justify-content: space-between;
    .ant-select-selector{
        border: 1px solid #c4c4c4;
    }
    .ant-select-selection-item{
        font-size: 17px;
        color: #393B3D;
    }
}

.cancel-section{
.resume-container{
    height: 90px;
}
.cancel-program{
    margin-top: 30px;
    .program-container{
        display: flex;
        align-items: center;
        padding: 0px !important;
        svg{
            height: 36px;
            width: 36px;
            margin-right: 10px;
        }
        span{
            font-size: 15px;
            color: #393B3D;
        }
        h4{
            padding: 0px;
            margin: 0px;
            font-size: 16px;
            font-weight: 500;
        }
        .items{
            width: 100%;
        .action-item{
            display: flex;
            align-items: center;
            border: 1px solid #ACACAC;
            border-radius: 16px;
            padding: 16px;
            gap: 16px;
            cursor: pointer;
            margin-top: 10px;
        }
        .item-active{
            border: 1px solid #ACACAC;
            background-color: #FFE1D6;
            border: 3px solid #FF6F33;
        }
    }
    }

    .btn-container{
        display: flex;
        align-items: center;
        margin-left: auto;
        justify-content: end;
        margin-top: 20px;

        button{
            margin-right: 10px;
        }
    }
}
}