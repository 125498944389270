@import '../../../../shared/styles/colors.scss';

.tabs-heading-wrapper {
    margin-bottom: 10px;
    height: 100%;

    &__heading {
        span {
            text-transform: uppercase;
            color: $orange-500;
            background: $white-000;
        }
    }
}

.suggestion-detail-wrapper~.dashBoardWrapper {
    width: 100%
}

.list-status{
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
}
.user-name{
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
}

.color-1{
    color: $grey-800;
}
.color-2{
    color: $blue-300;
}
.color-3{
    color: $green-700;
}
.color-4{
    color: $red-1000;
}

.suggestion-container {
    padding: 25px 35px;  
    &__header {
        display: flex;
        align-items: center;

        span {
            color: $orange-700;
            font-size: 15px;
            font-weight: 600;
            margin: 0px;
            cursor: pointer;
        }

        svg {
            margin: 0px 10px;
        }

        h4 {
            color: $grey-1000;
            font-size: 13px;
            font-weight: 600;
            margin: 0px;
        }
    }

    &__body {
        background: $white-000;
        display: grid;
        grid-template-columns: 1fr 1fr;
        

        &__header {
            display: grid;
            grid-template-columns: auto auto;
            padding: 10px;

            span {
                font-size: 16px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: left;
                color: $orange-700;
            }

            h4 {
                color: $grey-1000;
                font-size: 13px;
                font-weight: 500;
                margin: 0px;
                margin-left: auto;
            }
        }

        .feedback{
            border-right: 1px solid $grey-200;
        }

        &__content {
            padding: 11px 16px 11px 16px;
            border-radius: 16px;
            margin: 15px;
            background: $grey-100;
            min-height: 470px;
            span{
                font-size: 15px;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: left;
                color: $grey-1000;
            }
        }

    }
}