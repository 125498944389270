@import "../../styles/colors.scss";

.loader {
    width: 100%;
    height: 100%;
    background-color: #F8F9F9;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.7;
    position: fixed;
    z-index: 11111;
}