@import '../../styles/colors.scss';

.ant-table{
    border-radius: 13px !important; 
    box-shadow: 0px 2px 4px 0px #393B3D1A;
    border-bottom: 1px solid $grey-300;
}
.ant-table-wrapper .ant-table-thead >tr>th, .ant-table-wrapper .ant-table-thead >tr>td{
    background: $grey-100 !important;
    color: $grey-1000 !important;
    font-family: Nunito;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
    padding: 10px;
    &:first-child{
        border-top-left-radius: 13px !important;
    }

    &:last-child{
        border-top-right-radius: 13px !important;
    }

}
.ant-table-row{
    border-bottom: 1px solid $grey-300;
}

.ant-table-expanded-row{
    .ant-table-cell{
        border-radius: 16px;
        padding: 5px !important;
    background: $white-000 !important;
    border-top-right-radius: 0px !important;
    }
}

.ant-table-wrapper .ant-table-tbody >tr >td{
    border-top: 1px solid $grey-300;  
    padding: 10px 10px;  
}

.ant-table-cell::before{
    background: none !important;
}

.input-link{
    font-family: Nunito;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    color: $orange-700 !important;
    text-decoration: underline $orange-700;
    cursor: pointer;
}

.alert-notify{
    float: right;
    position: absolute;
    margin-left: 14px;
}

.table-row-dark{
    background-color: $orange-100;
}
.table-row-unread{
    background-color: $green-200;
    .ant-table-cell{
        font-weight: 700;
    }
}

.orange{
    color: $orange-700;
}
.green{
    color: $green-700;
}

.blue{
    color: $blue-300;
}

.ant-pagination .ant-pagination-item-active, .ant-pagination .ant-pagination-item-active a{
    color: $orange-700 !important;
    border-color: $orange-700 !important;
}

.ant-table-wrapper .ant-table-row-expand-icon:focus{
    color: $orange-700 !important;
}
.ant-table-wrapper .ant-table-row-expand-icon:hover{
    color: $orange-700 !important;
}

.grid-status{
    font-family: Nunito;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
    color: $green;    
}
