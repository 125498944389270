@import '../../../../shared/styles/colors.scss';

.subNavMenu {
    border-right: 1px solid $grey-200;
    background: $white-000;

    .menuItem {
        text-align: left;
        width: 220px;
        padding: 16px;
        background: $white-000;
        color: $grey-1000;
    }

    a {
        text-decoration: none;
    }

    .active {
        display: block;
        background-color: $orange-100;
        color: $orange-700;
        border-right: 1px solid $orange-700;
    }
}

.add-insurance-container {
    padding: 30px;

    &__body {
        background: $white-000;
        border-radius: 16px;
        padding: 20px;

        h4 {
            font-size: 15px;
            font-weight: 600;
            color: #393B3D;
        }

        .d-grid{
            display: grid;
            grid-template-columns: 15fr 0.5fr;
            align-items: center;
            gap: 0px 20px;
        }

        .d-grid-4{
            display: grid;
            grid-template-columns: 8fr 3fr 3fr 0.5fr;
            align-items: center;
            gap: 0px 20px;
            .ant-picker{
                width: 286px;
                height: 44px;
                background-color: $grey-100;
                border: none;
                border-radius: 16px;
            }
        }

        .add-new-plan-btn{
            cursor: pointer;
        }

        .add-insurance-bottom{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;
            .updated-date-wrapper{
                font-size: 17px;
                font-weight: 600;
                color: $grey-1000;
                .orange{
                    color: $orange-700;
                    margin-left: 8px;
                }
            }
            .btn-wrapper{
                display: flex;
                gap: 15px;
            }
        }
    }
}