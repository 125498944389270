@import '../../../../../../shared/styles/colors.scss';
.reassignModalContainer {
    width: 540px !important;
    .nameSection {
        display: flex;
        gap: 10px;
    }
    .paragraph-search{
        color: $grey-800;
font-size: 17px;
font-weight: 500;
    }
    .counties-dropdown-width{
        .ant-select-selection-search{
            width: auto !important;
        }
    }
}