.promo-preview{
    .preview-promo{
        height: auto;
        .promo-items-top{
            padding: 0px 24px 24px;
            border-bottom: 1px solid #D8E1FF;
            .promo-item-title{
                margin-bottom: 10px;
                span{
                font-family: Bricolage Grotesque 48pt Condensed;
                font-size: 22px;
                font-weight: 800;
                line-height: 19.8px;
                letter-spacing: 0.01em;
                text-align: left;
                color: #000000;
                }
            }
            .promo-image{
                img{
                    height: 260px;
                    width: 850px;
                    object-fit: cover;
                    border-radius: 8px;
                }
            }
        }

        .promo-items-desc{
            padding: 16px 24px 16px 24px;
            border-bottom: 1px solid #D8E1FF;
            .value{
                font-family: Inter;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: -0.03em;
                text-align: right;
                text-transform: capitalize;
            }
        }
        .promo-items{
            padding: 16px 24px 16px 24px;
            border-bottom: 1px solid #D8E1FF;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .value{
                font-family: Inter;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: -0.03em;
                text-transform: capitalize;
            }
            .label{
                font-family: Bricolage Grotesque;
                font-size: 16px;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: -0.03em;
                text-align: left;
                margin-right: 15px;

            }
            .promo-image{
                img{
                    height: 260px;
                    width: 850px;
                    object-fit: cover;
                    border-radius: 8px;
                }
            }

            .deep-link{
                color: #AD3E06;
                text-decoration: underline;
                font-size: 16px;
                font-weight: 600;
                display: flex;
                align-items: center;
                svg{
                    margin-left: 15px;
                    cursor: pointer;
                }
            }
            .deep-link-copy{
                color: #148051;
                font-size: 12px;
                display: flex;
                align-items: center;
            }
        
            .promo-link{
                display: flex;
                align-items: center;
                label{
                    margin-top: 5px;
                    margin-left: 5px;
                }
            }
        }
        .promo-container{
            display: flex;
            align-items: center;
            svg{
                height: 36px;
                width: 36px;
                margin-right: 10px;
            }
            span{
                font-size: 15px;
                color: #393B3D;
            }
        }

        .btn-container{
            display: flex;
            align-items: center;
            margin-left: auto;
            justify-content: end;

            button{
                margin-right: 10px;
            }
        }
        
        .promo-prview-link{
            display: flex;
            align-items: center;
            height: 78px;
            padding: 0px 24px 0px 24px;
            span{
                margin-top: 5px;
                margin-left: 5px;
            }
        }
    }
}
.add-preview-dialog{    
    width: 900px !important;
    .ant-modal-content{
        padding: 0px !important;
    }
    .ant-modal-title{
        padding: 20px;
    }
}