@import '../../styles/colors.scss';

.noAccessContainer {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 100px;

    svg {
        color: $orange-500;

    }

    p {
        color: $orange-500;
        font-size: 32px;
        text-align: center;
        height: 100%;
    }
}