@import '../../../../../../shared/styles/colors.scss';

.add-supply-container {
    background: $white-000;

    &__body {
        padding: 30px;

        h4 {
            color: $orange-700;
            font-weight: 500;
            font-size: 16px;
        }

        label {
            color: $grey-1000;
            font-size: 16px;
            font-weight: 500;
            display: block;
        }

        .text-length {
            text-align: end;
            color: $grey-1000;
            font-size: 15px;
            font-weight: 300;
            margin-top: 2px;
        }

        .supply-name-des-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 0px 35px;

            .rv-supply-dropdown {
                background-color: $grey-100;
                border-radius: 16px;
                width: 100% !important;

                .ant-select-selector {
                    border: none;
                    height: 48px;
                    background-color: transparent;
                    align-items: center;
                }
                .ant-select-selection-item,.ant-select-selection-placeholder{
                    font-size: 17px;
                }
                .ant-select-selection-placeholder{
                    color: $grey-400;
                }
            }
        }

        .supply-price-date-wrapper {
            display: grid;
            grid-template-columns: 286px 286px 286px;
            gap: 0px 35px;

            .ant-picker {
                width: 286px;
                height: 48px;
                background: $grey-100;
                border-radius: 16px;
            }
        }

        &--btns-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .updated-date-wrapper {
                span {
                    font-weight: 600;
                    font-size: 17px;
                    color: $grey-1000;
                }

                .orange {
                    color: $orange-700  !important;
                    margin-left: 8px;
                }
            }
        }

        .add-additional-supply {
            margin-top: 30px;
        }
    }

    &__at-home-body {
        .d-flex {
            display: flex;
            gap: 70px;
            margin-top: 15px;
        }

        .mt-10,
        .input-wrapper {
            margin-top: 15px;
        }

        .orange-finance {
            color: $orange-700;
            font-size: 16px;
            font-weight: 500;
            margin-top: 15px;
        }

        .ant-picker {
            background: $grey-100;
            height: 44px;
            width: 286px;
        }

        .procedure-short-input {
            width: 286px;

            .input-span-wrapper {
                display: flex;
                align-items: center;

                span {
                    // margin-right: 8px;
                    // margin-top: 20px;
                    color: $grey-800;
                    font-size: 17px;
                    font-weight: 500;
                }

                .percent {
                    margin-left: 8px;
                }
            }
        }

        .label-amount-wrapper {
            margin: 10px 0px;

            p {
                color: $grey-1000;
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 0px;
                margin-top: 0px;
            }

            span {
                color: $grey-1000;
                font-size: 17px;
                font-weight: 700;
            }
            .v-hidden{
                margin-left: 5px;
            }
        }

        .v-hidden{
            visibility: hidden;
        }

    }
}