@import '../../../shared/styles/colors.scss';

.emailformBox,
.messageBox {
    height: 460px;

    .messageBody {
        width: 100%;
        justify-content: space-between !important;
    }

    .resetWelcomeText {
        margin-bottom: 24px;
        width: 100%;

        .checkIcon {
            color: $orange-500;
            margin-right: 4px;
        }

        h2 {
            font-weight: 600;
            font-size: 15px;
            margin: 0 0 8px 0;
        }

        p {
            font-weight: 300;
            font-size: 15px;
            margin: 0;

        }
    }

    .actionContainer {
        margin-top: 124px;
    }
}

.messageBox {
    justify-content: flex-start !important;

}