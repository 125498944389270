@import '../../../../../../shared/styles/colors.scss';

.add-biomarker-container {
    padding: 30px;

    &__body {
        background: #fff;
        border-radius: 16px;
        padding: 20px;

        h4 {
            color: $orange-700;
            font-weight: 500;
            font-size: 16px;
            margin: 0px;
            margin-bottom: 10px;
        }

        .input-field-wrapper {
            .length {
                text-align: end;
                color: $grey-1000;
                font-size: 15px;
                font-weight: 300;
            }
        }

        .biomarker-d-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 10px;

            .biomarker-dropdown {
                background-color: #F4F4F4;
                border-radius: 16px;
                width: 100%;

                .ant-select-selector {
                    border: none;
                    min-height: 48px;
                    height: auto;
                    background-color: transparent;
                    align-items: center;
                }
            }
            .ant-picker {
                width: 100%;
                height: 44px;
                background-color: $grey-100;
                border: none;
                border-radius: 16px;
            }
            .add-cat-label{
                display: block;
            }
        }

        .tri-grid {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 10px;
            margin-top: 15px;
        }

        .action-btn-wrapper {
            display: flex;
            justify-content: space-between;
            margin-top: 25px;
        }
    }
}

.added-biomarker-container {
    &__body {
        label {
            display: block;
            margin: 10px 0px;
        }
    }
}

.add-bio-marker-dialog {
    width: 900px !important;

    .ant-checkbox-inner {
        width: 24px;
        height: 24px;
    }

    .bio-marker-checkbox {
        display: flex;
    }

    .ant-checkbox-checked .ant-checkbox-inner,
    .ant-checkbox-checked:hover,
    .ant-checkbox-inner:hover {
        background: $grey-1000 !important;
        border-color: $grey-1000 !important;
    }
}
.mt-10{
    margin-top: 15px;
}

.range-wrapper{
    .range-flex{
        display: flex;
        gap: 15px;
        input{
            width: 228px;
        }
    }
}
.range-d-flex{
    display: flex;
    justify-content: space-between;
}