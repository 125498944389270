@import '../../../../shared/styles/colors.scss';

.user-management-container {
    &__add-new-user-container {
        padding: 25px 180px;

        &--main-wrapper {
            background: $white-000;
            padding: 10px;
            border-radius: 16px;

            .heading {
                color: $orange-700;
                font-size: 16px;
                font-weight: 500;
            }

            .input-field-wrapper {
                display: grid;
                grid-template-columns: 0.5fr 1fr;
                align-items: center;

                .ant-form-item {
                    margin-bottom: 0px;
                }

                .d-grid {
                    display: grid;
                    grid-template-columns: 1fr 0.3fr 1fr;
                    gap: 0px 10px;
                }

                .contact-d-grid {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 10px;
                }

                .user-label {
                    color: $grey-1000;
                    font-size: 16px;
                    font-weight: 500;
                }

                .user-sub-label {
                    color: $grey-800;
                    font-size: 14px;
                    font-weight: 400;
                }

                .searchbox-container {
                    width: 100%;
                    background-color: $grey-100;

                    input {
                        background: transparent;
                    }

                }

                .ant-picker {
                    width: 286px;
                    height: 44px;
                    background-color: $grey-100;
                    border: none;
                    border-radius: 16px;
                }

                .rv-user-dropdown {
                    background-color: $grey-100;
                    border-radius: 16px;
                    width: 100% !important;

                    .ant-select-selector {
                        border: none;
                        height: 48px;
                        background-color: transparent;
                        align-items: center;
                    }

                    .ant-select-selection-placeholder {
                        color: $grey-1000;
                        font-size: 15px;
                        font-weight: 500;
                    }
                }
            }

            .add-new-user-action-btn-wrapper {
                display: flex;
                justify-content: space-between;
            }

            .border-line {
                background: $grey-200;
                height: 1px;
                border-radius: 16px;
                margin: 13px 0px;
            }

            .ant-checkbox-checked .ant-checkbox-inner{
                background: $orange-500;
                border-color: $orange-500;
            }
            .ant-checkbox-checked:hover .ant-checkbox-inner {  
                background: $orange-500 !important;
                border-color: $orange-500;
            }
            .ant-checkbox-wrapper:hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner{
                background: $orange-500 !important;
                border-color: $orange-500 !important;
            }
        }

        .countiesContainer {
            display: block;
        }
    }
}

.reset-password-container {
    p {
        font-size: 15px;
        font-weight: 300;
        color: $grey-1000;
    }

    &__sent {
        .d-flex {
            display: flex;
            align-items: center;

            svg {
                margin-right: 10px;
            }
        }

        span {
            color: $grey-1000;
            font-size: 15px;
            font-weight: 600;
        }
    }
}