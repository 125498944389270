.pet-diet{
    .buttons{
        margin-left: auto;
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    .pet-diet-heading{
        color: #393B3D;
        font-size: 16px;
        font-weight: 500;
        line-height: 21.82px;
    }

    .pet-grid{
        margin-top: 10px;
    }

}

.file-upload-popup{

    .pet-diet-heading{
        color: #393B3D;
        font-size: 16px;
        font-weight: 500;
        line-height: 21.82px;
        margin-bottom: 20px;
    }

    .upload-button{
        background-color: #AD3E06;
        color: #fff;
        padding: 8px 12px;
        border-radius: 8px;
        cursor: pointer;
        display: inline-block;
        line-height: 22px;  
        height: 38px;
        width: 170px;
        align-self: center;
    }

    .file-error{
        color: #ff4d4f;
        margin-left: 0px;
        font-size: 11px;
        font-weight: 500;
    }

    .popup-button{
        display: flex;
        flex: 1;
        justify-content: space-between;
        margin-top: 20px;
    }

    .summary{
        height: 300px;
        overflow-y: auto;
    }
}