@import '../../styles/colors.scss';

label {
    font-size: 16px !important;
    font-weight: 500;
    color: $grey-1000;

}

.ant-picker-status-error{
    background-color: $error-color !important;
}

.custom-ant-date-picker{
    .ant-picker-input{
        flex-direction: row-reverse;
        input{
            margin-left: 7px;
        }
    }
}