.searchbox-container {
    background: #ffffff;
    display: flex;
    align-items: center;
    border: 1px solid #E0E0E0;
    border-radius: 16px;
    height: 44px;
    width: 384px;
    padding-left: 5px;

    svg {
        margin-right: 10px;
    }

    input {
        width: 300px;
        border: none;
        outline: none;
        background: #ffffff !important;
        padding: 0px;
    }
    ::placeholder {
        font-size: 14px;
    }
    input:focus::placeholder{
            visibility: hidden;
    }
}
