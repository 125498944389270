@import '../../../../shared/styles/colors.scss';

.subNavMenu {
    border-right: 1px solid $grey-200;
    background: $white-000;

    .menuItem {
        text-align: left;
        width: 220px;
        padding: 16px;
        background: $white-000;
        color: $grey-1000;
        display: flex!important;
        justify-content: space-between;
        cursor: pointer;
        .counter{
            background-color: $alert-red;
            width: 20px;
            height: 20px;
            color: white;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: Nunito;
            font-size: 13px;
            font-weight: 700;
            line-height: 15px;
            letter-spacing: 0em;
            text-align: center;
        }
    }

    a {
        text-decoration: none;
    }

    .active {
        display: block;
        background-color: $orange-100;
        color: $orange-700;
        border-right: 1px solid $orange-700;
    }
}

.prm-dashboard-container {
    padding: 30px;

    &__body {
        &--header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            h4 {
                color: $grey-1000;
                font-size: 15px;
                font-weight: 600;
                margin-bottom: 0px;
            }
        }

        .metrics-container {
            display: flex;
            justify-content: space-between;
            margin-bottom: 25px;

            .revnue-metrics-wrappper,
            .appointment-metrics-wrappper {
                h4 {
                    font-size: 15px;
                    font-weight: 600;
                    margin-bottom: 5px;
                    color: $grey-1000;
                }

                .box-wrapper {
                    display: flex;

                    .right-zero {
                        margin-right: 0px !important;
                    }

                    .box {
                        background: $white-000;
                        border-radius: 16px;
                        margin-right: 15px;
                        padding: 8px 30px;
                        width: 175px;
                        height: 54px;

                        .label {
                            font-size: 15px;
                            font-weight: 600;
                            color: $grey-1000;
                            text-align: center;
                            margin-bottom: 7px;
                        }

                        .value {
                            font-size: 19px;
                            font-weight: 600;
                            color: $orange-700;
                            text-align: center;
                        }
                    }
                }
            }
        }

        .goals-container,
        .reviews-container,
        .complaints-container {
            margin-bottom: 5px;

            h4 {
                font-size: 15px;
                font-weight: 600;
                margin-bottom: 5px;
                color: $grey-1000;
            }

            .d-flex {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;
            }

            .ant-rate {
                color: $orange-500;
            }
        }
    }
}

.mrt-36 {
    margin-top: 36px;
}

.profile-setup-container {
    padding: 30px 90px;

    &__wrapper {
        background: $white-000;
        padding: 10px;
        border-radius: 16px;
        border-bottom: 1px solid $grey-200;

        .ant-form-item {
            margin-bottom: 0px;
        }

        h4 {
            color: $orange-700;
            font-size: 16px;
            font-weight: 500;
            margin-top: 0px;
        }

        .d-grid {
            display: grid;
            grid-template-columns: 0.5fr 1fr;
            align-items: center;
            padding: 10px 15px;
        }

        .rv-text-area {
            padding: 11px 16px;
        }

        .profile-label {
            color: $grey-1000;
            font-size: 16px;
            font-weight: 500;
        }

        .sub-label {
            color: $grey-800;
            font-size: 14px;
            font-weight: 400;
            max-width: 260px;
        }

        .dd-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 10px;
        }

        .border-line {
            background: $grey-200;
            height: 1px;
            border-radius: 16px;
            margin: 10px 0px;
        }

        .profile-btn-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 25px;
        }
    }
}

.my-provider-container {
    padding: 25px 35px;

    &__header {
        display: flex;
        align-items: center;

        span {
            color: $orange-700;
            font-size: 15px;
            font-weight: 600;
            margin: 0px;
            cursor: pointer;
        }

        svg {
            margin: 0px 10px;
        }

        h4 {
            color: $grey-1000;
            font-size: 15px;
            font-weight: 600;
            margin: 0px;
        }
    }

    &__body {
        background: $white-000;
        border-radius: 16px;

        &--header {
            padding: 10px;
            display: grid;
            grid-template-columns: 1.2fr 1fr;
            // gap: 15px;

            .left {
                display: grid;
                grid-template-columns: 1.2fr 1fr;
                border: 1px solid $grey-200;
                border-right: none;
                padding: 0px 5px;

                .left-left {
                    display: flex;

                    .info {
                        margin-left: 10px;
                    }

                    h5 {
                        margin: 0px;
                        color: $grey-1000;
                        font-size: 15px;
                        font-weight: 600;
                    }

                    .d-flex {
                        display: flex;
                        font-size: 14px;
                        font-weight: 400;

                        .rating {
                            color: $grey-800;
                        }

                        .reviews {
                            .red {
                                color: $orange-700;
                            }
                        }
                    }

                    .address,
                    .experience {
                        font-size: 14px;
                        font-weight: 400;
                        color: $grey-800;
                    }
                }

                .left-right {
                    .label-info-wrapper {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    span {
                        font-size: 14px;
                        font-weight: 400;
                        color: $grey-800;
                    }

                    h4 {
                        font-size: 14px;
                        font-weight: 700;
                        color: $grey-800;
                        margin: 0px;
                    }
                }
            }

            .right {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                gap: 4px;
                border: 1px solid $grey-200;
                padding: 5px;

                .popups-wrapper {
                    cursor: pointer;
                    background: $grey-100;
                    border-radius: 16px;
                    padding: 8px;
                    text-align: center;

                    &.bg-orange {
                        background: $orange-400;
                    }

                    svg {
                        display: block;
                        margin: 0px auto;
                    }

                    span {
                        display: inline-block;
                        max-width: 60px;
                        color: $grey-1000;
                        font-size: 11px;
                        font-weight: 500;
                        text-transform: capitalize;
                    }
                }
            }
        }

        &--about {
            border: 1px solid $grey-200;
            display: grid;
            grid-template-columns: 2fr 1fr 1.2fr;

            .about-area {
                border-right: 1px solid $grey-200;
                padding: 10px;

                h4 {
                    font-size: 16px;
                    font-weight: 500;
                    color: $orange-700;
                    margin: 0px;
                }

                p {
                    font-size: 15px;
                    font-weight: 300;
                    color: $grey-1000;
                }
            }

            

            .supervisor-area {
                border-right: 1px solid $grey-200;
                padding: 10px;

                h4 {
                    font-size: 16px;
                    font-weight: 500;
                    color: $orange-700;
                    margin: 0px;
                }

                p {
                    font-size: 15px;
                    font-weight: 300;
                    color: $grey-1000;
                }
                .section_2{
                    border-top: 1px solid $grey-200;
                    margin-top: 10px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    .wrapper{
                        padding: 5px 5px 5px 0px;
                    }
                    .label{
                        font-weight: 500;
                        line-height: 19px;
                        font-size: 15px;
                        color: $grey-800;
                    }
                }
                .section_1{
                    .wrapper{
                        justify-content: space-between;
                        display: flex;
                        padding: 10px 10px 0px 0px;
                        .label{
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 19px;
                            color:$grey-800;
                        }
                        .value{
                            font-weight: 700;
                            line-height: 19px;
                            font-size: 14px;
                            color: $grey-800;
                            display: flex;
                            width: 57px;
                            justify-content: space-between
                        }
                    }
                }
            }

            .rating-area {
                padding: 10px;

                .ant-rate {
                    color: $orange-500 !important;
                }

                h4 {
                    font-size: 16px;
                    font-weight: 500;
                    color: $orange-700;
                    margin: 0px;
                }

                .reviews-rating-wrapper {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    .rating {
                        color: $grey-1000;
                        font-size: 36px;
                        font-weight: 600;
                        margin-right: 10px;
                    }

                    .reviews {
                        .span {
                            color: $grey-800;
                            font-size: 16px;
                            font-weight: 500;
                            margin-top: 10px;
                        }
                    }
                }

                .label-star-wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .span {
                        color: $grey-1000;
                        font-size: 14px;
                        font-weight: 400;
                    }
                }
            }
        }

        &--education,
        &--delivery {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1.2fr;
            border-bottom: 1px solid $grey-200;

            .education-wrapper,
            .delivery-wrapper {
                padding: 15px;

                h4 {
                    color: $orange-700;
                    font-weight: 500;
                    font-size: 16px;
                    margin: 0px;
                }

                .span {
                    display: block;
                    margin: 5px 0px;
                    color: $grey-1000;
                    font-size: 15px;
                    font-weight: 400;
                }
            }

            .delivery-wrapper {
                .provider-detail-wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .bordered {
                    background: $grey-200;
                    height: 1px;
                    border-radius: 16px;
                    margin: 10px 0px;
                }

                .location-wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .revdocButton {
                        padding: 5px !important;
                    }
                }
            }

            .b-right {
                border-right: 1px solid $grey-200;
            }
        }

        &--delivery {
            border-bottom-right-radius: 16px;
            border-bottom-left-radius: 16px;
        }
    }
}

.assign-provider-grid {
    &__h3 {
        color: $orange-800;
        font-size: 17px;
        font-weight: 500;
    }

    .ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:last-child {
        font-weight: 500;
        font-size: 15px;
        color: $orange-700 !important;
        text-decoration: underline $orange-700;
    }

    .assign-provider-checkboxes {
        margin-left: 17px;
    }

    .ant-checkbox-inner {
        width: 24px;
        height: 24px;
    }

    .ant-checkbox-checked .ant-checkbox-inner,
    .ant-checkbox-checked:hover,
    .ant-checkbox-inner:hover {
        background-color: $grey-1000 !important;
        border-color: $grey-1000 !important;
    }
}

.provider-list-container {
    padding: 25px;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btn-flex {
            display: flex;
            align-items: center;

            svg {
                margin-right: 5px;
            }
        }

        .provider-list-dropdown {
            border-radius: 16px;
            width: 240px !important;

            .ant-select-selector {
                border: none;
                height: 44px;
                background-color: transparent;
                align-items: center;
            }

            .ant-select-selection-placeholder {
                display: flex;
                align-items: center;
            }
        }
    }

    &__body {
        margin-top: 15px;

        .ant-space {
            cursor: pointer;
        }
    }
}

.provider-pop-up-container-checklist{
    padding: 0px 0px !important;
}

.provider-pop-up-container {
    padding: 0px 24px;

    .ant-checkbox-disabled .ant-checkbox-inner:after{
        border-color: #fff;
    }

    &__checklist {
        .ant-form-item {
            margin-bottom: 10px !important;
            margin-right: 5px;
        }

        .scroll-bar {
            max-height: 420px;
            overflow-y: scroll;
            overflow-x: hidden;
        }

        .d-grid {
            display: grid;
            grid-template-columns: 1fr 0.6fr auto auto;
            align-items: baseline;
            border-bottom: 1px solid $grey-200;
            padding: 8px 16px 8px 72px;
            min-height: 60px;
            height: auto;
            align-items: center;

            svg{
                margin: auto;
                margin-bottom: 23px;
            }

            .span {
                color: $grey-700;
                font-size: 14px;
                font-weight: 400;

                &.ml-25 {
                    margin-left: -13px;
                }
            }

            .ant-picker {
                background: $grey-100;
                border-radius: 16px;
                border: none;
                height: 44px;
                width: 180px;

                .ant-picker-suffix {
                    display: none;
                }
            }

            .ant-checkbox-inner {
                width: 24px;
                height: 24px;
            }

            .ant-checkbox-checked .ant-checkbox-inner,
            .ant-checkbox-checked:hover,
            .ant-checkbox-inner:hover {
                background-color: $grey-1000 !important;
                border-color: $grey-1000 !important;
            }

            .text {
                font-size: 16px;
                font-weight: 500;
                color: $grey-700;
            }
        }

        .d-flex {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                margin-left: 8px;
            }
        }

        &--footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;

            .text {
                font-size: 14px;
                font-weight: 400;
                color: $grey-700;
            }

            .ant-checkbox-inner {
                width: 24px;
                height: 24px;
            }

            .ant-checkbox-checked .ant-checkbox-inner,
            .ant-checkbox-checked:hover,
            .ant-checkbox-inner:hover {
                background-color: $grey-1000 !important;
                border-color: $grey-1000 !important;
            }

            button {
                margin-left: 10px;
            }
        }
        .ant-checkbox-checked .ant-checkbox-inner:after{
            margin-left: 2px;
        }

        .ant-picker .ant-picker-clear{
            margin-top: 10px;
        }
    
    }

    &__medical {
        padding: 25px 10px;

        .d-flex {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 5px;

            .key {
                font-size: 15px;
                font-weight: 600;
                color: $grey-1000;
            }

            .value {
                font-size: 15px;
                font-weight: 300;
                color: $grey-1000;
            }
        }

        img {
            object-fit: contain;
            width: -webkit-fill-available;
        }
    }

    &__diploma {
        padding: 25px 10px;

        .school {
            h3 {
                font-size: 16px;
                font-weight: 500;
                color: $grey-1000;
                margin-bottom: 2px;
            }

            span {
                display: block;
                color: $grey-800;
                font-size: 14px;
                font-weight: 400;
            }
        }

        .border {
            background: $grey-200;
            height: 1px;
            border-radius: 16px;
            margin: 10px 0px;
        }

        .d-flex {
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;

            .key {
                font-size: 15px;
                font-weight: 600;
                color: $grey-1000;
            }

            .value {
                font-size: 15px;
                font-weight: 300;
                color: $grey-1000;
            }
        }

        .verified {
            display: flex;
            align-items: center;

            span {
                font-size: 11px;
                font-weight: 500;
                color: $grey-1000;
            }

            svg {
                margin-right: 5px;
            }
        }

        img {
            object-fit: contain;
            width: -webkit-fill-available;
        }
    }

    &__work {
        padding: 20px;

        .history {
            .key {
                font-size: 16px;
                font-weight: 500;
                color: $grey-1000;
            }

            .value {
                font-size: 14px;
                font-weight: 400;
                color: $grey-800;
            }

            .m-width {
                width: 130px;
            }

            .ms-width {
                width: 150px;
            }
        }

        .border {
            background: $grey-200;
            height: 1px;
            border-radius: 16px;
            margin: 10px 0px;
        }

        .verified {
            display: flex;
            align-items: center;

            span {
                font-size: 11px;
                font-weight: 500;
                color: $grey-1000;
            }

            svg {
                margin-right: 5px;
            }
        }
    }

    &__verification {
        .top {
            padding: 10px 20px;
            margin-top: 15px;
            border-top: 1px solid $grey-100;

            .dd-flex {
                display: flex;
                align-items: center;

                span {
                    color: $grey-1000;
                    font-size: 16px;
                    font-weight: 500;
                }

                svg {
                    margin-right: 5px;
                }
            }

            p {
                color: $grey-1000;
                font-size: 15px;
                font-weight: 300;
            }
        }

        .border {
            background: $grey-200;
            height: 1px;
            border-radius: 16px;
        }

        .d-flex {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .dark {
                color: $grey-1000;
                font-size: 15px;
                font-weight: 600;
                padding: 10px;
            }

            .pill {
                font-size: 13px;
                font-weight: 700;
                border-radius: 16px;
                padding: 4px 16px;
                margin-right: 5px;

            }

            .blue {
                color: $blue-600;
                background: $blue-100;
            }

            .green {
                color: $green-700;
                background: $green-100;
                padding: 10px 15px;
            }

            .key-value-pair {
                margin-left: 10px;

                .key {
                    color: $grey-800;
                    font-size: 11px;
                    font-weight: 500;
                }

                .value {
                    color: $grey-1000;
                    font-size: 14px;
                    font-weight: 400;
                }
            }

            .close-wrapper,
            .tick-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 8px 33px;
            }

            .close-wrapper {
                background: $orange-200;
            }

            .tick-wrapper {
                background: $green-100;
            }

            .medical-img {
                padding: 10px;
            }

            .h-110 {
                height: 110px;
            }
        }

        .demographic {
            background: $grey-000;
            color: $grey-1000;
            font-size: 15px;
            font-weight: 600;
            padding: 5px 10px;
        }

        .profile-and-background-wrapper {
            span {
                font-size: 14px;
                font-weight: 400;
                color: $grey-1000;
                margin-left: 10px;
            }

            .top {
                display: flex;
                padding: 0px 5px;
                border: none;

                svg {
                    margin-right: 5px;
                    height: 50px !important;
                    width: 50px !important;
                }

                p {
                    font-size: 16px;
                    font-weight: 500;
                    color: $grey-1000;
                    margin-bottom: 3px;
                }
            }
        }

        .my-prm-container {
            padding-bottom: 10px;
            .my-prm {
                font-size: 16px;
                font-weight: 500;
                color: $grey-1000;
                padding: 10px;
            }

            .msg-prm {
                color: $grey-800;
                font-size: 15px;
                font-weight: 600;
                margin-top: 5px;
                padding: 10px;
            }

            .verified {
                display: flex;
                align-items: center;
                padding: 10px;

                span {
                    font-size: 11px;
                    font-weight: 500;
                    color: $grey-1000;
                }

                svg {
                    margin-right: 5px;
                }
            }

            &__inner {
                border: 1px solid $grey-200;
                border-radius: 16px;
                padding: 10px;
                display: flex;
                margin: 10px;

                img {
                    object-fit: cover;
                }

                .name-wrapper {
                    margin-left: 10px;

                    .name {
                        color: $grey-1000;
                        font-size: 16px;
                        font-weight: 500;
                    }

                    .state-info {
                        color: $grey-800;
                        font-size: 14px;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}

.provider-popup {
    width: 630px !important;

    .ant-modal-content {
        padding: 0px;
    }

    .ant-modal-header {
        padding: 20px 24px;
        padding-bottom: 0px !important;
        margin-bottom: 0px;
    }
}

.add-new-specialty-container {
    padding: 30px;

    &__wrapper {
        background: $white-000;
        padding: 10px;
        border-radius: 16px;

        h1 {
            font-size: 16px;
            font-weight: 500;
            color: $orange-700;
        }

        .d-grid {
            display: grid;
            grid-template-columns: 1fr 0.6fr;
            gap: 10px;
            align-items: center;

            .d-flex {
                display: flex;
                align-items: center;
                gap: 10px;

                .ant-picker {
                    background: $grey-100;
                    border-radius: 16px;
                    border: none;
                    height: 44px;
                    width: 100%;

                    .ant-picker-suffix {
                        display: none;
                    }
                }

                svg {
                    margin-top: 15px;
                    margin-left: 5px;
                }
            }
        }

        .action-btn-wrapper {
            display: flex;
            justify-content: space-between;

            .updated-date-wrapper {
                span {
                    font-weight: 600;
                    font-size: 17px;
                    color: #393B3D;
                }

                .orange {
                    color: #AD3E06 !important;
                    margin-left: 8px;
                }
            }
        }
    }

    .ml-20 {
        margin-left: 20px;
    }
}

.complaint-container {
    padding: 25px;

    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__detail {
        display: grid;
        grid-template-columns: 1fr 1.2fr;
        margin-top: 5px;
        border-top: 1px solid $grey-200;
        border-bottom: 1px solid $grey-200;
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;

        .left {
            border-right: 1px solid $grey-200;
            padding: 10px;

            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;

                h5 {
                    color: $orange-700;
                    font-weight: 500;
                    font-size: 13px;
                    margin: 0px;
                }

                span {
                    color: $grey-800;
                    font-weight: 500;
                    font-size: 13px;
                }
            }

            &__body {
                p {
                    color: $grey-1000;
                    font-size: 15px;
                    font-weight: 300;
                }

                .action-btn-wrapper {
                    margin-top: 30px;
                    display: flex;

                    .res-gift {
                        display: flex;
                        align-items: center;
                        margin-right: 20px;
                        cursor: pointer;

                        svg {
                            margin-right: 8px;
                        }

                        span {
                            font-size: 17px;
                            font-weight: 400;
                            color: $orange-700;
                        }
                    }
                }
            }
        }

        .right {
            display: grid;
            grid-template-columns: 1fr 1fr;

            .key-value {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .key {
                    color: $grey-1000;
                    font-size: 15px;
                    font-weight: 600;
                }

                .value {
                    color: $grey-1000;
                    font-size: 15px;
                    font-weight: 300;
                }
            }

            .left {
                h5 {
                    color: $orange-700;
                    font-weight: 500;
                    font-size: 13px;
                    margin: 0px;
                }
            }

            .right-right {
                padding: 10px;

                h5 {
                    color: $orange-700;
                    font-weight: 500;
                    font-size: 13px;
                    margin: 0px;
                }
            }
        }
    }
}

.reveive-msg {
    color: $white-000;
    background: $orange-700;
    font-weight: 600;
    font-size: 15px;
    border-radius: 16px;
    padding: 8px;
}

.resolution-detail-container,
.gift-card-container {
    padding: 20px;

    .resolution-dropdown {
        width: 296px;

        .ant-select-selector {
            border: none;
            border-radius: 16px;
            background: $grey-100;
            height: 44px;
            align-items: center;
        }
    }

    .input-field-wrapper {
        margin-bottom: 10px;
    }

    &__action-btn {
        display: flex;
        justify-content: flex-end;

        button {
            margin-left: 10px;
        }
    }
}

.gift-card-container {
    &__amount {
        margin-bottom: 10px;

        .btns {
            display: flex;
            gap: 10px;

            .amount {
                background: $grey-100;
                border-radius: 16px;
                padding: 4px 16px;
                cursor: pointer;
            }

            .orng-amount {
                cursor: pointer;
                background: $orange-700;
                color: $white-000;
                border-radius: 16px;
                padding: 4px 16px;
            }
        }
    }

    .input-fields-wrapper {
        margin-bottom: 10px;
    }

    &__action-btn {
        margin-top: 60px;
        display: flex;
        justify-content: flex-end;
    }
}

.relative-div {
    position: relative;

    .transition-input {
        input {
            width: 93%;
        }
    }

    .submt-btn-icon {
        position: absolute;
        top: 15px;
        right: 15px;
    }
}

.chat-header {
    background: $orange-100;
    padding: 8px;

    .img-text-wrapper {
        display: flex;
        align-items: center;

        .img {
            position: relative;

            .bronze {
                position: absolute;
                bottom: 0px;
                font-size: 9px;
                font-weight: 600;
                background: linear-gradient(to top, $orange-900, $orange-1000);
                color: $white-000;
                border-radius: 11px;
                padding: 2px 5px;
                border-top: 1px solid $white-000;
            }

            .chat-tick {
                position: absolute;
                top: -4px;
                left: -4px;
            }
        }
    }

    .text {
        font-size: 15px;
        font-weight: 600;
        color: $grey-1000;
        margin-left: 15px;
    }
}

.message-all-provider-container {
    &__header {
        display: flex;
        gap: 5px;
        align-items: center;
        margin-bottom: 10px;

        span {
            font-size: 15px;
            font-weight: 300;
            color: $grey-1000;
        }
    }

    &__text-are {
        margin-bottom: 10px;

        .length {
            font-size: 15px;
            font-weight: 400;
            color: $grey-1000;
            text-align: end;
        }
    }

    &__btn {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
    }
}