@import "../../styles/colors.scss";

.revdocButton {
    padding: 8px 20px;
    height: unset;
    font-size: 17px;
    font-weight: 600;
    border-radius: 8px;
}

.filled,
.filled:hover,
.filled:disabled {
    color: $white-000 !important;
    background-color: $orange-700 !important;
    border-color: $orange-700 !important;
    font-size: 16px;
    font-weight: 500;
}

.filled:disabled {
    opacity: 0.5;
}

.outlined,
.outlined:hover {
    color: $orange-700 !important;
    border: 1px solid $orange-700;
    border-color: $orange-700 !important;
}