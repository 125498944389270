@import '../../styles/colors.scss';

.stateCountyContainer {
    display: flex;
    margin-bottom: 10px;
    align-items: center;

    div {
        display: flex !important;

        .ant-select {
            min-width: 101px !important;
            margin-right: 10px;
        }

        .select-box {
            margin-right: 10px;
            // width: 200px;
        }
    }

    .rv-user-dropdown {
        background-color: $grey-100;
        border-radius: 16px;
        width: 230px !important;

        .ant-select-selector {
            border: none;
            width: -webkit-fill-available;
            height: auto !important;
            // padding: 10px 20px 10px 10px;
            background-color: transparent;
            align-items: center;
        }

        .ant-select-selection-overflow {
            width: -webkit-fill-available;
        }

        .ant-select-selection-placeholder {
            color: $grey-1000;
            font-size: 14px !important;
            font-weight: 500;
            margin-left: 5px;
        }
    }

    .ant-select-selection-item {
        font-size: 16px;
    }
}