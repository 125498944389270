@import '../../shared/styles/colors.scss';

.revdoc-setup-container {
    padding: 36px;
    
    .procedure-status{
        color:#0A8D56;
        font-family: Nunito;
        font-size: 15px;
        font-weight: 700;
        letter-spacing: 0em;            
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 36px;
        .rv-setup-dropdown {
            width: 240px !important;

            .ant-select-selector {
                height: 44px;
                align-items: center;
            }

            .ant-select-selection-placeholder {
                font-size: 15px !important;
                font-weight: 500 !important;
            }
        }
    }
}
