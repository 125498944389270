@import '../../styles/colors.scss';

.transition-input {
  position: relative;
  padding: 1.25rem 1rem;
  background-color: $grey-100;
  border-radius: 16px;
  transition: .2s ease-in-out;
  height: 48px;
  min-width: auto;

  label,
  input {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 16px;
    outline: none;

    :disabled {
      opacity: 0.2;
    }
  }

  label {
    display: flex;
    align-items: center;
    color: $grey-500;
    transition: .25s ease-in-out;
    transform-origin: top left;
  }

  input {
    border: none;

    &:focus {
      box-shadow: none !important;

      +label {
        transform: scale(.8) translateY(-.7rem);
      }
    }
  }

  input:not(:placeholder-shown)+label {
    transform: scale(.8) translateY(-.7rem);
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.transition-input input::placeholder {
  visibility: hidden;
}