@import '../../../shared/styles/colors.scss';
.rv-checkbox-container{
    .ant-checkbox-inner {
        height: 24px;
        width: 24px;
        border: 1px solid $grey-1000;
    }

    .rv-checkbox {
        margin-bottom: 10px;
    }
    .ant-checkbox-checked .ant-checkbox-inner{
        background: #F16924;
    }
    .ant-checkbox-checked:hover .ant-checkbox-inner {  
        background: #F16924 !important;
    }
}