@import '../../../../shared/styles/colors.scss';
.home-screen-container{
    padding: 30px;

    &__wrapper {
        background: $white-000;
        padding: 10px;
        padding-left: 20px;
        border-radius: 16px;

        h1 {
            font-size: 16px;
            font-weight: 500;
            color: $orange-700;
            margin: 0;
            margin-bottom: 10px;
        }

        .header{
            display: flex;
            justify-content: space-between;
        }

        .section-order{
            display: flex;
            margin-left: auto;
            width: 240px;
            justify-content: space-between;
            height: 40px;
            .del-item{
                margin-top: 15px;
            }
            .ant-select{
                width: 80px;
            }
        }
    }

    .hm-item-error{
        color: #ff4d4f;
    }

    .item-invalid{
        background-color: $error-color !important;
    }

    .row-warning{
        display: flex;
        .info{
            margin-right: 5px;
            cursor: pointer;
        }
    }

    .action-btn-wrapper {
        display: flex;
        justify-content: space-between;

        .updated-date-wrapper {
            span {
                font-weight: 600;
                font-size: 17px;
                color: #393B3D;
            }

            .orange {
                color: #AD3E06 !important;
                margin-left: 8px !important;
            }
        }

        .btn-promo-outer{
            width: 320px;
            justify-content: space-between;
            display: flex;
            button{
                margin-left: auto;
            }
        }
    }

    .add-icon{
        display: flex;
        gap: 5px;
        align-items: center;
        cursor: pointer;

        label{
            cursor: pointer;
        }
    }

    .horizontal-container{
        display: grid;
        gap: 25px;
    }

    .section{
        border-bottom: 1px solid #e0e0e0;
        font-size: 16px !important;
        font-weight: 700;
        padding-left: 0px;
        margin-top: 10px;
        margin-top: 10px;
        padding-bottom: 10px;
        .ant-form-item{
            margin-bottom: 10px;
            margin-top: 10px;
        }
        label{
            margin-top: 10px;
        }
        .check-label-promo{
            padding-top: 10px;
            display: flex;
        }
    }
    .no-border{
        border-bottom: none;
        padding: 0px;
    }

    .last-section{
        .ant-btn { margin-top: 10px;}
        .drp-type-val{
            width: 300px;
        }
        .itemTable div.ant-row:first-child th:last-child{
            border-top-right-radius: 16px;
            border-top-left-radius: 16px;
        }
        .itemTable div.ant-row:first-child th:first-child{
            border-top-right-radius: 16px;
            border-top-left-radius: 16px;
        }
        .itemTable{
            margin-top: 10px;

            .ant-col:first-child{
                .th{
                border-top-left-radius: 16px;
                }
            }

            .ant-col:last-child{
                .th{
                border-top-right-radius: 16px;
                }
            }
            .ant-row:nth-child(n+2){
                height: 70px;
                display: flex;
                align-content: center;
                .select-box{
                    padding-top: 22px;
                }
                .ant-form-item-explain{
                    padding-bottom: 15px;
                }
            }
           
            .th {
                box-shadow: none;
                background: #F4F4F4 !important;
                color: #393B3D !important;
                font-family: Nunito;
                font-size: 15px;
                font-weight: 500;
                letter-spacing: 0em;
                text-align: left;
                padding: 10px;
                flex:1;
                height: 48px;
                border-bottom: 1px solid #CCCCCC;
                border-right: none;
            }
            .ant-col-3{
                text-transform: capitalize;
            }
            .tb{
                align-items: center;
                box-shadow: 0px 0px 4px #393B3D1A;
                background-color: white;
                border-bottom: 1px solid #CCCCCC;
                padding: 13px 8px;
                margin-left: 0px !important;
                margin-right: 0px !important;
                
                padding-bottom: 0px ;
                padding-top: 0px;
                .pd10{
                    padding-top: 24px ;
                }
                .td{
                }
            }
            .rv-dropdown {
                background-color: $grey-100;
                border-radius: 16px;
                width: 100% !important;
        
                .ant-select-selector {
                    border: none;
                    height: 48px;
                    background-color: transparent;
                    align-items: center;
                }
                .ant-select-selection-item,.ant-select-selection-placeholder{
                    font-size: 17px;
                }
                .ant-select-selection-placeholder{
                    color: $grey-400;
                }
            }

            .ant-switch{
                margin-left: auto;
                height: 30px;
                border: none;
                .ant-switch-handle{
                    height: 25px;
                }
            }
            .active{
                background-color: #FF6F33;
            }
            .inactive{
                background-color: #e0e0e0;
            }
           
            .ant-switch.ant-switch-checked:hover{
                background-color: #FF6F33;
                color: #FF6F33;
            }
            .ant-switch-inner-checked{
                background-color: #FF6F33;
                color: #FF6F33;
            }
            .ant-switch-checked{
                background-color: #FF6F33;
            }
            .ant-switch-inner-unchecked{
                color: #e0e0e0 !important;
            }
            
        }
    }

    .label-links{
        margin-top: 30px;
    }

    .thumbnail-warning{
        background: #FFF0E9;
        border-radius: 8px;
        height: 56px;
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 15px;
        padding-left: 14px;
        font-weight: 300;
        margin-bottom: 15px;
        margin-top: 15px;
    }

    .ant-switch{
        margin-left: auto;
        height: 30px;
        border: none;
        .ant-switch-handle{
            height: 25px;
        }
    }
    .active{
        background-color: #FF6F33;
    }
    .inactive{
        background-color: #e0e0e0;
    }
   
    .ant-switch.ant-switch-checked:hover{
        background-color: #FF6F33;
        color: #FF6F33;
    }
    .ant-switch.ant-switch-unchecked:hover{
        background-color: #e0e0e0;
        color: #e0e0e0 !important;
    }
    .ant-switch-inner-checked{
        background-color: #FF6F33;
        color: #FF6F33 !important;
    }
    .ant-switch-checked{
        background-color: #FF6F33;
    }
   
    .ant-switch-inner-unchecked{
        color: #e0e0e0 !important;
    }
    .ant-switch-inner-unchecked{
        color: #e0e0e0 !important;
        display: none !important;
    }
    .welcome-meesage-info{
        margin-left: auto;
        width: max-content;
        display: block;
        margin-top: 5px;
    }
    .singlecard-qtn{
        display: flex;
        align-items: center;
        margin-top: 0px;
        margin-bottom: 30px;
        .ant-switch{
            margin-left: 30px;
        }
        .member-label{
            margin-left: 5px;
            font-size: 17px;
            font-weight: 500;
            line-height: 22px;
            text-align: left;
        }
    }
}

.home-screen-item-container{
    
    .search-section{
        display: flex;
        align-items: center;
        gap: 25px;
    }
    .drp-section{
        height: 100px;
    }
    .rv-dropdown {
        border-radius: 16px;
        width: 100% !important;

        .ant-select-selector {
            height: 44px;
            background-color: transparent;
            align-items: center;
            width: 395px;
        }
        .ant-select-selection-item, .ant-select-selection-placeholder{
            font-size: 17px;
        }
        .ant-select-selection-placeholder{
            color: $grey-400;
        }
    }
.home-screen-item-container__body__search {
    .searchbox-container {
      width: 100% !important;
    }
    margin-bottom: 10px;
    .search-promo-text {
        margin-bottom: 15px;
        height: 61px;
        margin-top: 20px;
    }
  }

  .action-btn-wrapper {
    display: flex;
    margin-left: auto;
    gap: 10px;
    width: 175px;
    }
}

.member-home-section-screen {
    display: block;
    span{
        font-size: 15px;
        font-weight: 500;
    }
    .section-types{
        margin-top: 15px;
        display: flex;
        gap: 10px;
        width: 574px;
        .items, .items-single{
            border: 1px solid #ACACAC;
            border-radius: 16px;
            width: 180px;
            height: 260px;
            gap: 24px;
            padding: 24px 16px 24px 16px;
            cursor: pointer;
            overflow: hidden;

            span{
                font-size: 15px;
                font-weight: 600;
            }

            img{
                margin-top: 15px;
            }
        }

        .items-single{
            width: 182px;
        }
    }    

}

.member-home-section-outer{
    width: 606px !important;
}

.carousel-card{
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 15px;
    margin: 20px;
}