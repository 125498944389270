@import '../../../../shared/styles/colors.scss';

.headerContainer {
    border-bottom: 4px solid $orange-500;

    .header {
        height: 68px;
        padding: 16px 32px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;

        .iconContainer {
            img {
                margin: 0 13px;
            }
        }
    }
}