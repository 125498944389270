@import '../../../../shared/styles/colors.scss';
.program-provider-container{
    padding: 17px;
    padding-top: 0px;
    .search-promo-text{
        h2{
            font-family: Nunito;
            font-size: 15px;
            font-weight: 600;
            line-height: 20.46px;
            color: #393B3D;
        }
    }
    .btn-container{
        display: flex;
        align-items: center;
        margin-left: auto;
        justify-content: end;
        margin-top: 10px;

        button{
            margin-right: 10px;
        }
    }

}
.add-preview-dialog{    
    width: 900px !important;
    .ant-modal-content{
        padding: 0px !important;
        .ant-checkbox-inner {
            width: 24px;
            height: 24px;
          }
        
          .bio-marker-checkbox {
            display: flex;
          }
        
          .ant-checkbox-checked .ant-checkbox-inner,
          .ant-checkbox-checked:hover,
          .ant-checkbox-inner:hover {
            background: $grey-1000 !important;
            border-color: $grey-1000 !important;
          }
    }
    .ant-modal-title{
        padding: 20px;
    }
}