.program-preview{
    padding-bottom: 2px;
    .preview-promo{
        height: auto;
        .program-items-top{
            padding: 0px 24px 24px;
            border-bottom: 1px solid #D8E1FF;
            .program-item-title{
                margin-bottom: 10px;
                h4{
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 19.8px;
                    letter-spacing: 0.01em;
                    text-align: left;
                    color: #000000;
                }
            }
            .program-image{
                img{
                    height: 284px;
                    object-fit: cover;
                    border-radius: 8px;
                }
            }
        }
        .program-items-sub-header{
            background-color: #D8E1FF;
            height: 48px;
            align-items: center;
            padding: 16px;
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            span{
                font-family: Inter;
                font-size: 16px;
                font-weight: 500;
                line-height: 16px;
                text-align: left;
            }
            .action{
                width: 50px;
                justify-content: space-between;
                display: flex;
                cursor: pointer;
            }
        }
        .program-items-desc{
            padding: 16px 24px 16px 24px;
            border-bottom: 1px solid #D8E1FF;
            .label{
                font-family: Bricolage Grotesque;
                font-size: 16px;
                font-weight: 600;
                line-height: 16px;
                letter-spacing: -0.03em;
                text-align: left;
                margin-right: 15px;

            }
            .value{
                font-family: Inter;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: -0.03em;
                text-align: right;
                text-transform: capitalize;
            }
        }
        .program-items-appointments{
            padding: 16px 24px 16px 24px;
            border-bottom: 1px solid #D8E1FF;
            h4{
                margin-top: 0px;
                margin-bottom: 0px;
            }
            .value{
                font-family: Inter;
                font-size: 15px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: -0.03em;
                text-transform: capitalize;
            }
            .label{
                font-family: Bricolage Grotesque;
                font-size: 16px;
                font-weight: 600;
                line-height: 16px;
                letter-spacing: -0.03em;
                text-align: left;
                margin-right: 15px;

            }
            .action{
                display: flex;
                gap: 5px;
                svg{
                    margin-top: 4px;
                }
                span{
                    margin-top: 1px;
                }
            }
            .card{
                margin-top: 5px;
                .card-item{
                    display: flex;
                    width: 447px;
                    border-radius: 8px;
                    padding: 2px;
                    height: 30px;
                    margin-bottom: 5px;
                    .left{
                        display: flex;
                        align-items: center;
                        gap: 7px;
                        img{
                            height: 28px;
                            width: 28px;
                            border-radius: 15px;
                        }
                    }
                }

                .card-program-items{
                    display: flex;
                }
            }
        }
        .program-items{
            padding: 16px 24px 16px 24px;
            border-bottom: 1px solid #D8E1FF;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .value{
                font-family: Inter;
                font-size: 15px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: -0.03em;
                text-transform: capitalize;
            }
            .label{
                font-family: Bricolage Grotesque;
                font-size: 16px;
                font-weight: 600;
                line-height: 16px;
                letter-spacing: -0.03em;
                text-align: left;
                margin-right: 15px;

            }
            .program-image{
                img{
                    height: 260px;
                    width: 850px;
                    object-fit: cover;
                    border-radius: 8px;
                }
            }
            
            .deep-link{
                color: #AD3E06;
                text-decoration: underline;
                font-size: 16px;
                font-weight: 600;
                display: flex;
                align-items: center;
                svg{
                    margin-left: 15px;
                    cursor: pointer;
                }
            }
            .deep-link-copy{
                color: #148051;
                font-size: 12px;
                display: flex;
                align-items: center;
            }
        
            .program-link{
                display: flex;
                align-items: center;
                label{
                    margin-top: 5px;
                    margin-left: 5px;
                }
            }
        }
        .program-container{
            display: flex;
            align-items: center;
            svg{
                height: 36px;
                width: 36px;
                margin-right: 10px;
            }
            span{
                font-size: 15px;
                color: #393B3D;
            }
        }

        .btn-container{
            display: flex;
            align-items: center;
            margin-left: auto;
            justify-content: end;

            button{
                margin-right: 10px;
            }
        }
        
        .program-prview-link{
            display: flex;
            align-items: center;
            height: 78px;
            padding: 0px 24px 0px 24px;
            span{
                margin-top: 5px;
                margin-left: 5px;
            }
        }
    }

    .program-summary{
        border: 1px solid #D8E1FF;
        padding: 0px;
        margin: 20px;
        margin-top: 0;
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;

        .heading{
            background-color: #D8E1FF;
            border-top-right-radius: 16px;
            border-top-left-radius: 16px;
            height: 48px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px;
            span{
                font-family: Inter;
                font-size: 16px;
                font-weight: 500;
                line-height: 16px;
                text-align: left;
            }
            .action{
                width: 50px;
                justify-content: space-between;
                display: flex;
                cursor: pointer;
            }
        }
    }
}
.add-preview-dialog{    
    width: 900px !important;
    .ant-modal-content{
        padding: 0px !important;
    }
    .ant-modal-title{
        padding: 20px;
    }
}