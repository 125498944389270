@import "./shared/styles/colors.scss";
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,500;1,1000&display=swap");

.termsCol {
  p {
    width: 20vw;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

body,
html {
  padding: 0;
  margin: 0;
  background-color: $grey-000;
  height: 100%;
}

* {
  font-family: "Nunito", sans-serif;
}

.rv-main-container {
  height: 100%;

  .dashBoardContainer {
    height: calc(100% - 72px);

    .navMenu {
      border-right: 1px solid $grey-200;
      width: 96px;
      font-size: 13px;
      background-color: $grey-000;

      a {
        text-decoration: none;
      }
    }

    .dashBoardWrapper {
      height: 100%;
    }
  }
}

#root {
  height: 100%;
}

// .form-input-success-border {
//   border: 1px solid #80c483;
//   outline: none;
// }

// .form-input-error-border {
//   border: 1px solid #e76262;
//   outline: none;
// }

// .form-input-invalid-name-border {
//   border: 1px solid #e76262;
//   outline: none;
// }
