@import '../../../../../../shared/styles/colors.scss';

.scheduleContainer {
  border-top: 1px solid $grey-200;
  display: block;
  background-color: $grey-100;
  padding: 10px;

  .availability {

    &__item {
      padding: 16px;
      border-radius: 12px;
      border: 2px;
      display: flex;
      margin-bottom: 10px;
      border: 2px solid $blue-200;
      background-color: $white-000;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 18px;
        font-weight: 700;
        line-height: 16px;
        letter-spacing: 0.01em;
        text-align: left;
        color: $black-1000;
      }

      &__time {
        display: block;
        margin-bottom: 10px;

        span {
          font-size: 14px;
          font-weight: 500;
          line-height: 14px;
          letter-spacing: -0.03em;
          text-align: left;

        }
      }
    }
  }
}