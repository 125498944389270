.addon{
    &__button{
        font-weight: 500;
        padding: 5px;
        width: 184px;
        margin: 10px 0px;
        color: #fff !important;
        background-color: #AD3E06;
        border-color: #AD3E06 !important;
    }

    &__saveaddon{
        margin-left: auto;
        display: flex;
        margin-top: 30px;
        justify-content: center;
        color: #fff !important;
        background-color: #AD3E06;
        border-color: #AD3E06 !important;
    }
}

.addonlist{
    &__container{
        height: auto;
        overflow-y: auto;
        margin-top: 10px;
    }
}

.add-bio-marker-dialog{

    .no-record{
        width: 100%;
        height: 100px;
        border: 1px solid #5c5c5c;
        align-items: center;
        border-style: dashed;
        text-align: center;
        justify-content: center;
        display: flex;
    }

}