@import '../../../../../../shared/styles/colors.scss';

.add-procedure-container {
  padding: 30px;

  &__body {
    background: #fff;
    border-radius: 16px;
    padding: 20px;

    h4 {
      color: $orange-700;
      font-weight: 500;
      font-size: 16px;
      margin: 0px;
      margin-bottom: 10px;
    }

    .input-field-wrapper {
      .length {
        text-align: end;
        color: $grey-1000;
        font-size: 15px;
        font-weight: 300;
      }
    }

    .tri-grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 10px;
      margin-top: 15px;
    }

    .action-btn-wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 25px;
    }
  }
}

.mt-10 {
  margin-top: 15px;
}

.range-wrapper {
  .range-flex {
    display: flex;
    gap: 15px;
    input {
      width: 228px;
    }
  }
}
.range-d-flex {
  display: flex;
  justify-content: space-between;
}

.added-procedure-container__body__search {
  .searchbox-container {
    width: 100% !important;
  }
  margin-bottom: 10px;
}
.search_product{
  display: flex;
  align-items: center;
  gap: 5px;

  .searchbox-container {
    width: auto !important;
  }

  .ant-select-selector {
    height: 42px !important;
    padding: 5px 11px !important;
    .ant-select-selection-item{
      font-size: 14px;
    }
  }
}

.search-promo-text{
  margin-bottom: 15px;
}