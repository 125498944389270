$orange-100: #FFF0E9;
$orange-200: #FEFBF1;
$orange-400: #FF8F57;
$orange-500: #F16924;
$orange-700: #AD3E06;
$orange-800: #471800;
$orange-900: #8A6B1C;
$orange-1000: #7E4511;

$grey-000: #F8F9F9;
$grey-100: #F4F4F4;
$grey-200: #E0E0E0;
$grey-300: #CCCCCC;
$grey-400: #BBB7B7;
$grey-500: #A3A3A3;
$grey-700: #78797A;
$grey-800: #626466;
$grey-1000: #393B3D;

$white-000: #fff;

$error-color: #ebdbdb;

$green: #0A8D56;
$green-100: #F2FDF8;
$green-200: #F4FFE1;
$green-700: #148051;

$blue-100: #E8F6FC;
$blue-200: #D8E1FF;
$blue-300: #2CAADD;
$blue-600: #1B80A2;

$red-1000: #B11E1E;

$black-1000: #000000;

$alert-red: #E90000;