@import '../../../../../../shared/styles/colors.scss';

.iconSelect{
    display: flex;
    align-items: center;
    img{
        margin: 0 0 4px 0;
    }
    p{
        margin: 0 0 0px 3px;

    }
}
.add-category-setup-container {
    padding: 30px;

    &__body {
        background: $white-000;
        border-radius: 16px;
        padding: 20px;

        h4 {
            color: $orange-700;
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            margin-bottom: 10px;
            margin-top: 0px;
        }

        &--input-field-wrapper {
            display: flex;
            justify-content: space-between;
            gap: 0px 20px;
            .label-input-wrapper{
                flex:1;
                align-items: center;
                display: flex;
                .rv-supply-dropdown {
                    background-color: $grey-100;
                    border-radius: 16px;
                    width: 377px;
                    .ant-select-selector {
                        border: none;
                        height: 48px;
                        background-color: transparent;
                        align-items: center;
                    }
                    .ant-select-selection-item,.ant-select-selection-placeholder{
                        font-size: 17px;
                        display: flex;
                    }
                    .ant-select-selection-placeholder{
                        color: $grey-400;
                    }
                }

            }
            .ant-picker {
                width: 377px;
                height: 48px;
                background: $grey-100;
                border-radius: 16px;
            }

            label {
                color: $grey-1000 !important;
            }
        }

        &--btns-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .updated-date-wrapper {
                span {
                    font-weight: 600;
                    font-size: 17px;
                    color: $grey-1000;
                }

                .orange {
                    color: $orange-700 !important;
                    margin-left: 8px;
                }
            }
        }


        .add-cat-label {
            display: block;
        }
        .ant-picker .ant-picker-input >input{
            color: rgba(0, 0, 0, 0.88);
        }
    }
}