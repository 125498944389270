@import '../../../../shared/styles/colors.scss';

.add-new-symptom-container {
    padding: 30px;

    &__wrapper {
        background: $white-000;
        padding: 10px 20px;
        border-radius: 16px;

        h4 {
            font-size: 16px;
            font-weight: 500;
            color: $orange-700;
            margin-top: 0px;
        }

        .d-grid {
            display: grid;
            // grid-template-columns: 11fr 0.5fr;
            align-items: center;
            gap: 25px;

            .svg {
                margin-top: 15px;
            }
        }

        .dd-grid {
            padding-left: 25px;
            padding-top: 10px;
            display: grid;
            grid-template-columns: 6.3fr 2.5fr 2.5fr 0.5fr;
            // align-items: center;
            gap: 25px;

            .svg {
                margin-top: 10px;
            }

            .ant-picker {
                width: 196px;
                height: 44px;
                border-radius: 16px;
                background: $grey-100;
                border: none;
            }
        }

        .add-new-symptom-btn {
            margin-top: 10px;
            margin-left: 25px;
        }

        .add-new-symptom-action-btn {
            display: flex;
            justify-content: space-between;
            .updated-date-wrapper{
                span {
                    font-weight: 600;
                    font-size: 17px;
                    color: #393B3D;
                }

                .orange {
                    color: #AD3E06 !important;
                    margin-left: 8px;
                }
            }
        }

        .symptom-date-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 10px;
            .ant-picker {
                width: 100%;
                height: 44px;
                background-color: $grey-100;
                border: none;
                border-radius: 16px;
            }
            .add-cat-label{
                display: block;
            }
        }
    }

    .systoms-dropdown {
        background-color: #F4F4F4;
        border-radius: 16px;
        width: 98%;

        .ant-select-selector {
            border: none;
            min-height: 48px;
            height: auto;
            background-color: transparent;
            align-items: center;
        }
    }
}