@import '../../styles/colors.scss';

label {
    font-size: 16px !important;
    font-weight: 500;
    color: $grey-1000;

}

.field,
input[type='text'],
input[type='password'] {
    font-size: 17px;
    border-radius: 16px;
    padding: 11px 16px;
    background-color: $grey-100 !important;
    // color: $grey-500;
    border: 0;
}

.ant-form-item-has-error {

    .field,
    input[type='text'],
    input[type='password'] {
        background-color: $error-color;
    }
}