@import '../../../../shared/styles/colors.scss';

.welcome-message-container{
    padding: 30px;

    &__wrapper {
        background: $white-000;
        padding: 10px;
        padding-left: 20px;
        border-radius: 16px;

        h1 {
            font-size: 16px;
            font-weight: 500;
            color: $orange-700;
            margin: 0;
        }

        .d-grid {
            display: grid;
            grid-template-columns: 1fr 0.6fr;
            gap: 10px;
            align-items: center;

            .d-flex {
                display: flex;
                align-items: center;
                gap: 10px;

                .ant-picker {
                    background: $grey-100;
                    border-radius: 16px;
                    border: none;
                    height: 44px;
                    width: 100%;

                    .ant-picker-suffix {
                        display: none;
                    }
                }

                svg {
                    margin-top: 15px;
                    margin-left: 5px;
                }
            }
        }

        .action-btn-wrapper {
            display: flex;
            justify-content: space-between;

            .updated-date-wrapper {
                span {
                    font-weight: 600;
                    font-size: 17px;
                    color: #393B3D;
                }

                .orange {
                    color: #AD3E06 !important;
                    margin-left: 8px;
                }
            }
        }
    }
    .action-btn-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        margin-bottom: 5px;
        width: 100%;
        .btn-promo-outer{
            width: 200px;
            justify-content: space-between;
            display: flex;
            button{
                margin-left: auto;
            }
        }
      }
      .section{
        border-bottom: 1px solid #e0e0e0;
        font-size: 16px !important;
        font-weight: 700;
        padding-left: 0px;
        margin-top: 10px;
        margin-top: 10px;
        padding-bottom: 10px;
        .ant-form-item{
            margin-bottom: 10px;
            margin-top: 10px;
        }
        label{
            margin-top: 10px;
        }
        .check-label-promo{
            padding-top: 10px;
            display: flex;
        }
    }
    .no-border{
        border-bottom: none;
        padding: 0px;
    }
    .green-button{
        background-color: #148051;
        border-color: #148051;
        color: #fff;
        padding: 8px 20px;
        height: unset;
        font-size: 17px;
        font-weight: 500;
        border-radius: 8px;
        
    }
    .text-length{
        text-align: right;
    }
    .green-button.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover{
        background-color: #148051 !important;
        border-color: #148051 !important;
    }
    .non-editable{
        color: #393B3D;
        font-family: Nunito;
        font-size: 16px;
        font-weight: 700;
        line-height: 21.82px;
        text-align: left;
        display: flex;
        padding-bottom: 20px;
    }
}

.welcome-message-preview-dialog{
    .ant-modal-content{
        padding: 0px !important;
        .ant-modal-header{
            padding: 20px;
            border-bottom: 1px solid #e0e0e0;
        }
       
        .message-body{
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 280px;
                height: auto;
                background: #F6F6F6;
                border-radius: 15px 15px 15px 0;
                color: #393B3D;
                margin: auto;
                padding: 20px;
        }

        .message-body:before {
            content: "";
            position: absolute;
            z-index: 0;
            bottom: 0;
            left: -7px;
            height: 20px;
            width: 20px;
            background: #F6F6F6;
            border-bottom-right-radius: 15px;
          }
          .message-body:after {
            content: "";
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: -10px;
            width: 10px;
            height: 20px;
            background: white;
            border-bottom-right-radius: 10px;
          }
          
    }
    .action-btn-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        margin-bottom: 5px;
        width: 100%;
        border-top: 1px solid #e0e0e0;
        .btn-promo-outer{
            width: 190px;
            justify-content: space-between;
            display: flex;
            margin-left: auto;
            padding: 10px;
            button{
                margin-left: auto;
            }
        }
      }
}