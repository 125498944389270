@import '../../../../shared/styles/colors.scss';
.profile-banner-container {
    padding: 25px 35px;

    &__header {
        display: flex;
        align-items: center;

        span {
            color: $orange-700;
            font-size: 15px;
            font-weight: 600;
            margin: 0px;
            cursor: pointer;
        }

        svg {
            margin: 0px 10px;
        }

        h4 {
            color: $grey-1000;
            font-size: 15px;
            font-weight: 500;
            margin: 0px;
        }
    }

    &__body {
        background: $white-000;
        border-radius: 16px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom: 1px solid $grey-200;
        padding: 16px;
        margin-top: 15px;

        &--header {
            display: grid;
            grid-template-columns: 1.2fr 1fr;

            h3{
                margin-top: 0px;
                margin-bottom: 0px;
                color: $grey-1000;
                font-weight: 600;
                font-size: 15px;
                line-height: 20px;
            }

            

            .seprator{
                color: #AD3E06;
            }

            .left {
                display: grid;
                grid-template-columns: 1.2fr 1fr;
                border: none;
                padding: 0px 5px;

                .left-left {
                    display: flex;

                    .info {
                        margin-left: 10px;
                    }

                    h5 {
                        margin: 0px;
                        color: $grey-1000;
                        font-size: 15px;
                        font-weight: 600;
                    }

                    .d-flex {
                        display: flex;
                        font-size: 14px;
                        font-weight: 400;

                        .rating {
                            color: $grey-800;
                        }

                        .reviews {
                            .red {
                                color: $orange-700;
                            }
                        }
                    }

                    .address,
                    .experience {
                        font-size: 14px;
                        font-weight: 400;
                        color: $grey-800;
                        gap: 8;
                        line-height: 19px;
                    }

                    .phone{
                        font-size: 14px;
                        font-weight: 400;
                        color: $grey-800;
                        gap: 4;
                        line-height: 19px;
                    }
                }

                .left-right {
                    .label-info-wrapper {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    span {
                        font-size: 14px;
                        font-weight: 400;
                        color: $grey-800;
                    }

                    h4 {
                        font-size: 14px;
                        font-weight: 700;
                        color: $grey-800;
                        margin: 0px;
                    }
                }
            }

            .right {
                display: grid;
                grid-template-columns: auto;
                gap: 4px;
                padding: 5px;
                margin-left: auto;
                align-items: center;
                .popups-wrapper {
                    cursor: pointer;
                    background: $grey-100;
                    border-radius: 16px;
                    padding: 8px;
                    text-align: center;

                    &.bg-orange {
                        background: $orange-400;
                    }

                    svg {
                        display: block;
                        margin: 0px auto;
                    }

                    span {
                        display: inline-block;
                        max-width: 60px;
                        color: $grey-1000;
                        font-size: 11px;
                        font-weight: 500;
                        text-transform: capitalize;
                    }
                }
            }
        }

        &--about {
            border: 1px solid $grey-200;
            display: grid;
            grid-template-columns: 1fr 0.4fr;

            .about-area {
                border-right: 1px solid $grey-200;
                padding: 10px;

                h4 {
                    font-size: 16px;
                    font-weight: 500;
                    color: $orange-700;
                    margin: 0px;
                }

                p {
                    font-size: 15px;
                    font-weight: 300;
                    color: $grey-1000;
                }
            }

            .rating-area {
                padding: 10px;

                .ant-rate {
                    color: $orange-500 !important;
                }

                h4 {
                    font-size: 16px;
                    font-weight: 500;
                    color: $orange-700;
                    margin: 0px;
                }

                .reviews-rating-wrapper {
                    display: flex;
                    align-items: center;
                    margin-bottom: 10px;

                    .rating {
                        color: $grey-1000;
                        font-size: 36px;
                        font-weight: 600;
                        margin-right: 10px;
                    }

                    .reviews {
                        .span {
                            color: $grey-800;
                            font-size: 16px;
                            font-weight: 500;
                            margin-top: 10px;
                        }
                    }
                }

                .label-star-wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .span {
                        color: $grey-1000;
                        font-size: 14px;
                        font-weight: 400;
                    }
                }
            }
        }

        &--education,
        &--delivery {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1.2fr;
            border-bottom: 1px solid $grey-200;

            .education-wrapper,
            .delivery-wrapper {
                padding: 15px;

                h4 {
                    color: $orange-700;
                    font-weight: 500;
                    font-size: 16px;
                    margin: 0px;
                }

                .span {
                    display: block;
                    margin: 5px 0px;
                    color: $grey-1000;
                    font-size: 15px;
                    font-weight: 400;
                }
            }

            .delivery-wrapper {
                .provider-detail-wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .bordered {
                    background: $grey-200;
                    height: 1px;
                    border-radius: 16px;
                    margin: 10px 0px;
                }

                .location-wrapper {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .revdocButton {
                        padding: 5px !important;
                    }
                }
            }

            .b-right {
                border-right: 1px solid $grey-200;
            }
        }

        &--delivery {
            border-bottom-right-radius: 16px;
            border-bottom-left-radius: 16px;
        }
    }
}