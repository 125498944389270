@import '../../../../../../shared/styles/colors.scss';

.quickLinksTable div.ant-row:last-child{
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
}

.quickLinksTable{
    .th {
        box-shadow: 0px 0px 4px #393B3D1A;
        background: #F4F4F4 !important;
        color: #393B3D !important;
        font-family: Nunito;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0em;
        text-align: left;
        padding: 10px;
        flex:1;
        border-bottom: 1px solid #CCCCCC;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;

    }
    .tb{
        align-items: center;
        box-shadow: 0px 0px 4px #393B3D1A;
        background-color: white;
        border-bottom: 1px solid #CCCCCC;
        padding: 13px 8px;
        margin-left: 0px !important;
        margin-right: 0px !important;
        
        padding-bottom: 0px ;
        padding-top: 0px;
        .pd10{
            padding-top: 24px ;
        }
        .td{
        }
    }
    .rv-dropdown {
        background-color: $grey-100;
        border-radius: 16px;
        width: 100% !important;

        .ant-select-selector {
            border: none;
            height: 48px;
            background-color: transparent;
            align-items: center;
        }
        .ant-select-selection-item,.ant-select-selection-placeholder{
            font-size: 17px;
        }
        .ant-select-selection-placeholder{
            color: $grey-400;
        }
    }
    
}