.promo-listing{
    .cancel-promo{
        height: 90px;
        margin-top: 30px;
        .promo-container{
            display: flex;
            align-items: center;
            svg{
                height: 36px;
                width: 36px;
                margin-right: 10px;
            }
            span{
                font-size: 15px;
                color: #393B3D;
            }
        }

        .btn-container{
            display: flex;
            align-items: center;
            margin-left: auto;
            justify-content: end;
            margin-top: 20px;

            button{
                margin-right: 10px;
            }
        }
    }

    .list-promo-filter{
        font-size: 16px;
        color: #393B3D;
        display: flex;
        margin-left: 1px;
        margin-bottom: 5px;
        font-weight: 500;
    }

    .promo-drp{
            border: 1px solid #e5e5e5 !important;
            .ant-select-selection-item{
                font-size: 14px !important;
                font-weight: 500 !important;
            }
    }
}