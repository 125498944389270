@import '../../../../../../shared/styles/colors.scss';
.alertSection{
    width: 990px !important;
    .ant-modal-content{
        padding-left: 0px;
        padding-right: 0px;
        .ant-modal-header{
            padding-left: 15px;
        }
        .ant-table-thead{
            text-transform: uppercase;
            font-weight: 500;
        }
        .ant-spin-nested-loading{
            background-color: $grey-100;
        }
    }
}