@import '../../../shared/styles/colors.scss';
.radio-container {
display: flex;
align-items: center;
margin: 5px 0px;
  input[type='radio']:after {
      width: 24px;
      height: 24px;
      border-radius: 24px;
      top: -5px;
      left: -3px;
      position: relative;
      background-color: $white-000;
      content: '';
      display: inline-block;
      visibility: visible;
      border: 1px solid $grey-1000;
  }

  input[type='radio']:checked:after {
      width: 24px;
      height: 24px;
      border-radius: 24px;
      top: -5px;
      left: -3px;
      position: relative;
      background-color: $orange-500;
      content: '';
      display: inline-block;
      visibility: visible;
      border: 1px solid $grey-1000;

  }

  span.label {
      margin-left: 15px;
      color: $grey-1000;
  font-size: 17px;
  font-weight: 500;
    margin-top: 5px;
  }
}