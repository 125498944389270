@import '../../../../shared/styles/colors.scss';

.program-container{
    padding: 30px;

    &__wrapper {
        background: $white-000;
        padding: 10px;
        padding-left: 20px;
        border-radius: 16px;
        padding-top: 0px;

        h1 {
            font-size: 16px;
            font-weight: 500;
            color: $orange-700;
            margin: 0;
        }

        .d-grid {
            display: grid;
            grid-template-columns: 1fr 0.6fr;
            gap: 10px;
            align-items: center;

            .d-flex {
                display: flex;
                align-items: center;
                gap: 10px;

                .ant-picker {
                    background: $grey-100;
                    border-radius: 16px;
                    border: none;
                    height: 44px;
                    width: 100%;

                    .ant-picker-suffix {
                        display: none;
                    }
                }

                svg {
                    margin-top: 15px;
                    margin-left: 5px;
                }
            }
        }

        .calc-loader{
            margin-left: 10px;
            font-size: 17px;
        }

        .calc-wrapper{
            width: 488px !important;
        }

        .action-btn-wrapper {
            display: flex;
            justify-content: space-between;

            .updated-date-wrapper {
                span {
                    font-weight: 600;
                    font-size: 17px;
                    color: #393B3D;
                }

                .orange {
                    color: #AD3E06 !important;
                    margin-left: 8px !important;
                }
            }

            .btn-promo-outer{
                width: 320px;
                justify-content: space-between;
                display: flex;
                button{
                    margin-left: auto;
                }
            }
        }

        .lbl-name-telemedicine{
            width: 250px;
            .member-label{
                font-size: 14px;
            }
        }

        .telemedicine-hide{
            display: none !important;
        }

        .discount-type-container{
            display: flex;
            margin-top: 10px;
            margin-bottom: 10px;

            .discount-type-sub-container{
                display: flex;
                align-items: center;

                .drp-discount{
                    margin-left: 15px;
                }

                .ant-select-selector{
                    font-size: 17px;
                    background-color: #F4F4F4;
                    height: 50px;
                }
            }

            span{
                font-size: 17px;
                font-weight: 500;
                color: #393B3D;
            }
        }

        .info-text{
            font-size: 17px;
            font-weight: 500;
            color: #393B3D;
            margin-left: 10px;
        }

        .check-label-promo{
            display: flex;
            margin-bottom: 10px;
        }

        .member-type{
            .provider-wrapper{
                h3{
                    font-weight: 500 !important;
                }
                .rv-checkbox-container:last-child .rv-checkbox{
                    margin-bottom: 0px !important;
                }
            }
            label{
                margin-top: 0px !important;
            }

            .program-type-pet{
                margin-left: 30px;
                h3{
                    margin-top: 0px !important;
                    margin-bottom: 0px !important;
                }
            }
        }
    }
    .action-btn-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        margin-bottom: 5px;
        width: 100%;
        .btn-promo-outer{
            gap: 5px;
            justify-content: space-between;
            display: flex;
            button{
                margin-left: auto;
            }
        }
      }
      .section{
        border-bottom: 1px solid #e0e0e0;
        font-size: 16px !important;
        font-weight: 700;
        padding-left: 0px;
        margin-top: 10px;
        margin-top: 10px;
        padding-bottom: 10px;
        .program-code{
            width: 300px;
            display: block;
            margin-top: 5px;
        }
        .ant-form-item{
            margin-bottom: 10px;
            margin-top: 10px;
        }
        label{
            margin-top: 10px;
        }
        .check-label-promo{
            padding-top: 10px;
            display: flex;
        }
        .ant-select{
            width: 100px !important;
            margin-bottom: 15px;
        }
        .ant-select-selector{
            font-size: 17px;
            background-color: #F4F4F4;
            height: 50px;
            padding-left: 15px;
            padding-top: 10px;
            border: none;
            .ant-select-selection-item{
                font-size: 17px;
            }
        }
        .drp-month{
            .amount-text{
                margin-top: 0px !important;
            }
        }
    }
    .no-border{
        border-bottom: none;
        padding: 0px;
    }
    .green-button{
        background-color: #148051;
        border-color: #148051;
        color: #fff;
        padding: 8px 20px;
        height: unset;
        font-size: 17px;
        font-weight: 500;
        border-radius: 8px;
        
    }
    .text-length{
        text-align: right;
    }
    .green-button.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover{
        background-color: #148051 !important;
        border-color: #148051 !important;
    }
    .non-editable{
        color: #393B3D;
        font-family: Nunito;
        font-size: 16px;
        font-weight: 700;
        line-height: 21.82px;
        text-align: left;
        display: flex;
        padding-bottom: 20px;
    }

    .checkbox-prgm {
        .ant-form-item{
        margin-bottom: 0px !important;
        label {
            margin-top: 0px !important;
        }
        .rv-checkbox {
            margin-bottom: 0px !important;
        }
    }
    
    }

    .promo-region{
        .ant-select-selector{
            background-color: #F4F4F4;
            border-radius: 20px;
            border: none;
            width: 200px;
            height: 50px;
            align-items: center;
            .ant-select-selection-item{
                font-size: 16px;
                margin-left: 10px;
            }
            .ant-select-in-form-item{
                width: 355px !important;
            }
        }

        .sub-container-region{
            margin-left: 25px;
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            width: 100%;
            justify-content: space-between;
            .ant-input{
                width: auto;
                display: flex;
            }
            svg{
                cursor: pointer;
            }
        }

        
    }
    .apmt-add{        
        margin-left: 25px;
    }
    .add-icon{
        width: fit-content;
        align-items: center;
        display: flex;
        margin-top: 10px;
        cursor: pointer;

        label{
            margin-left: 5px;
            cursor: pointer;
            margin-top: 7px;
        }

        svg{
            margin-top: 5px;
            cursor: pointer;
        }
    }
    .program-section{
        margin-top: 20px;
        .ant-input{
            width: 358px;
            display: flex;
        }
    }
    .program-assessment{
        margin-top: 20px;
        .assessment-text{
            display: flex;
            align-items: normal;
            .lbl-name{
                margin-top: 25px;
                margin-left: 10px;
            }
            .lbl-name-bold{
                label{
                font-weight: 700;
                font-size: 17px;
                margin-top: 25px;
                margin-left: 10px;
                }
            }
            .ant-input{
                width: 180px;
                font-size: 17px;
                border-radius: 16px;
                padding: 11px 16px;
                background-color: #F4F4F4;
                border: 0;
                display: flex;
            }
            .ant-input-affix-wrapper {
                background-color: #F4F4F4;
                border: 0;
                width: 180px;
                margin-left: 5px;
                border-radius: 16px;
            }
        }
            .ant-input{
                width: 127px;
                font-size: 17px;
                border-radius: 16px;
                padding: 11px 16px;
                background-color: #F4F4F4;
                border: 0;
                display: flex;
            }
            .ant-input-affix-wrapper {
                background-color: #F4F4F4;
                border: 0;
                width: 127px;
                margin-left: 5px;
                border-radius: 16px;
            }
        textarea{
            width: 100% !important;
        }
    }

    .program-optin{
        margin-top: 20px;
        .last-section{
            display: flex;
            align-items: center;
            width: 447px;
            height: 70px;
            .ant-form-item{
                margin-top: 25px;
                width: 90px;
                }
        }
        .lbl-cal-info{
            display: flex;
            margin-left: auto;
            align-items: center;
            justify-content: space-between;
            gap: 70px;
            label{
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: -0.03em;
            text-align: center;
            }
        }

        .sub-info{
            font-size: 11px;
            width: 420px !important;
        }
        .lbl-name-bold{
            label{
            font-weight: 700;
            font-size: 17px;
            margin-top: 20px;
            }
            .orange {
                color: #AD3E06 !important;
                margin-left: 2px !important;
            }
        }
        .program-type-info{
            margin-top: 15px;
        }

        .qty-wrapper{
            display: flex;
            margin-left: auto;
            gap: 20px;
            width: 200px;
        }
        .assessment-text{
            display: flex;
            align-items: center;
            margin-left: auto;
            .lbl-name{                
                font-size: 13px;
                font-weight: 500;
                line-height: 13px;
                letter-spacing: -0.03em;
                text-align: left;
                margin-top: 25px;
                margin-left: 10px;
            }
            .lbl-name-bold{
                label{
                font-weight: 700;
                font-size: 17px;
                margin-top: 20px;
                margin-left: 10px;
                }
            }
        }
            .ant-input{
                width: 80px;
                font-size: 17px;
                border-radius: 16px;
                padding: 11px 16px;
                background-color: #F4F4F4;
                border: 0;
                display: flex;
                margin-left: 5px;
            }
            .ant-input-affix-wrapper {
                background-color: #F4F4F4;
                border: 0;
                width: 80px;
                margin-left: 5px;
                border-radius: 16px;
            }
        textarea{
            width: 100% !important;
        }
    }
    .lbl-amount-name{
        label{
            font-size: 14px !important;
        }
    }

    .lbl-name-delivery{
        label{
            font-size: 13px !important;
        }
    }
    
    .last-section{
        font-size: 16px !important;
        font-weight: 700;
        padding-left: 0px;
        margin-top: 10px;
        textarea{
            width: 100% !important;
        }
        .sub-program{
            color: #393B3D;
            font-size: 16px;
            font-weight: 500;
            line-height: 21.82px;
            text-align: left;
        }

    }

    .pgm-procedure-section{
        margin-top: 10px;
    }
.calc-info{
    .last-section{
    height: 40px !important;
    }
}
    .ant-slider{
        width: 447px;
        margin-top: 0px;
        .ant-slider-track{
            background-color: #FF6F33;
        }
        .ant-slider-handle::after {
            width: 28px;
            height: 28px;
            box-shadow: 0px 6px 12px 0px #0000001A;
            box-shadow: 0px 0.5px 4px 0px #0000001A;
        }
        .ant-slider-rail {
            background-color: #78788033;
        }
        .ant-slider-rail, .ant-slider-track, .ant-slider-step{
            margin-top: 10px;
        }
    }

    .green-check{
        .ant-checkbox-checked .ant-checkbox-inner{
            background: #cbf38a !important;
        }
        .ant-checkbox-checked:hover .ant-checkbox-inner{
            background: #cbf38a !important;
        }
    }

    .button-container{
        width: 100%;
        margin-top: 15px;
        display: flex;
    }

    .appointment-section{
        border: 1px solid #CBD7FF;
        border-radius: 8px;
        padding: 16px;
        margin-bottom: 15px;
        width: 96%;
        margin-left: 35px;       
        .apmt-desc{
        .ant-form-item{
            margin-bottom: 0px;
        }
    }
        .appointment-link-section{
            border: 1px solid #CBD7FF;
            border-radius: 8px;
            padding: 16px;
            width: 585px;
            margin-bottom: 20px;
        }
        .apmt-linked-provider{
            .section{
                border-bottom: none !important;
                height: 40px;
            }
        }
        .ant-switch{
            margin-left: auto;
            height: 30px;
            border: none;
            .ant-switch-handle{
                height: 25px;
            }
        }
        .active{
            background-color: #FF6F33;
        }
        .inactive{
            background-color: #e0e0e0;
        }
       
        .ant-switch.ant-switch-checked:hover{
            background-color: #FF6F33;
            color: #FF6F33;
        }
        .ant-switch-inner-checked{
            background-color: #FF6F33;
            color: #FF6F33;
        }
        .ant-switch-checked{
            background-color: #FF6F33;
        }
        .ant-switch-inner-unchecked{
            color: #e0e0e0;
        }
        .apmt-linked-qtn{
            display: flex;
            .member-label{
                margin-left: 5px;
                font-size: 17px;
                font-weight: 500;
                line-height: 22px;
                text-align: left;
            }
        }
        .apmt-linked-provider{
            border-top: 1px solid #CBD7FF;
            margin-top: 20px;
            padding-top: 20px;
            .info-label{
                margin-left: 5px;
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;
                text-align: left;
            }
        }
        .appointment-heading{
            display: flex;
            font-family: Bricolage Grotesque 48pt Condensed;
            font-size: 22px;
            font-weight: 800;
            line-height: 19.8px;
            letter-spacing: 0.01em;
            text-align: left;
            color: #000000;
            align-items: baseline;
            .name-appointment{
                display: flex;
                align-items: baseline;
                gap: 10px;
                font-size: 16px;
                font-weight: 500;    
                width: 100%;
                height: 80px;
                .ant-input{
                    width: 500px !important;
                }
                .ant-form-item-explain-error{
                    width: 250px !important;
                }
            }
            .del-appointment{
                margin-left: auto;  
                display: flex;
                align-items: baseline;
                gap: 10px;
                font-size: 16px;
                font-weight: 500;  
                     
                .ant-select{
                    width: 75px;
                } 
                svg{
                    cursor: pointer;
                    margin-top: 5px;
                }
            }
        }
        .apmt-type-item{
            display: flex;
            align-items: flex-start;
        .apmt-type-info{
            font-size: 11px;
            font-weight: 500;
            line-height: 12.65px;
            letter-spacing: -0.03em;
            text-align: left;
            color: #000000;
            margin-bottom: 5px;
        }
        .apmt-type-info:last-child{
            margin-bottom: 20px;
        }
        .apmt-item-text{
            font-size: 14px;
            font-weight: 500;
            line-height: 14px;
            letter-spacing: -0.03em;
            text-align: left;
            margin-bottom: 5px;
                    }
                }
        
    }
    .apmt-sub-section{
        .heading{
            font-size: 16px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: -0.03em;
            text-align: left;
            color: #000000;
            margin-bottom: 5px;
        }
        .desc{
            font-size: 11px;
            font-weight: 500;
            line-height: 12.65px;
            letter-spacing: -0.03em;
            text-align: left;
            color: #000000;
        }
    }
    .program-calc{
        border-bottom: 2px solid #CBD7FF;
        padding-bottom: 25px;
    }
    .opt-in-calc{
        padding-bottom: 25px;
    }
    .apmt-top-border{
        border-top: 2px solid #CBD7FF;
        padding-top: 20px;
        .ant-form-item-control-input{
            min-height: 0px;
        }
        .ant-form-item-explain-error{
            width: auto;
        }
    }
    .apmt-border{
        border-bottom: 2px solid #CBD7FF;
        .lbl-name{
            margin-top: 15px;
            margin-right: 5px;
        }
        .amount-member-text{
            align-items: normal !important;
        }
        .ant-input{
            width: 120px;
            padding-left: 11px;
        }
        .ant-input-affix-wrapper {
            background-color: #F4F4F4;
            border: 0;
            width: 120px;
            padding-left: 0px
        }
    }

    .prg-sub-section{
        display: block;
        border-bottom: 2px solid #CBD7FF;
        width: 447px;
    }

    .pgm-calc-extra{
        width: 490px !important;
    }

    .sub-info-cancel{
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: -0.03em;
        text-align: left;
    }
    .sub-info-revdoc{
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: -0.03em;
        text-align: left;
        margin-top: 20px;
    }

    .member-label{
        margin-left: 10px;
        font-size: 17px;
        font-weight: 500;
        line-height: 22px;
        text-align: right;
    }

    .pgm-member{
        display: flex;
        align-items: center;
        .amount-text{
            margin-top: 10px;
            .ant-form-item{
                margin-bottom: 0px !important;
            }
        }
        .amount-member-text{
        .ant-input{
            width: 120px;
            padding-left: 11px;
        }
        .ant-input-affix-wrapper {
            background-color: #F4F4F4;
            border: 0;
            width: 120px;
            padding-left: 0px
        }
    }
    }

    .amount-text{
        display: flex;
        align-items: center;
        .ant-input{
            width: 125px;
            font-size: 17px;
            border-radius: 16px;
            padding: 11px 16px;
            background-color: #F4F4F4;
            border: 0;
            display: flex;
            margin-left: 5px;
        }
        .ant-input-affix-wrapper {
            background-color: #F4F4F4;
            border: 0;
            width: 125px;
            margin-left: 5px;
            border-radius: 16px;
        }
        .ant-form-item-control-input-content{
                display: flex;
                align-items: center;
        }
    }
    .amount-text-left{
        .ant-input{
        margin-left: 15px;
        }

    }
.amount-member-text{
        display: flex;
        align-items: center;
        .ant-input{
            width: 180px;
            font-size: 17px;
            border-radius: 16px;
            padding: 11px 16px;
            background-color: #F4F4F4;
            border: 0;
            display: flex;
            margin-left: 5px;
        }
        .ant-input-affix-wrapper {
            background-color: #F4F4F4;
            border: 0;
            width: 180px;
            margin-left: 5px;
            border-radius: 16px;
        }
        .ant-form-item-control-input-content{
                display: flex;
                align-items: center;
        }
    }
    .promo-cal{
        .ant-picker{
            display: block;
            background-color: #F4F4F4;
            padding: 12px;
            border-radius: 20px;
            border: none;
            height: 50px;
            width: 175px;
            margin-top: 15px;
            .ant-picker-input input {
                font-size: 17px;
            }
        }
    }

    .promo-region{
        .ant-select-selector{
            background-color: #F4F4F4;
            border-radius: 20px;
            border: none;
            width: 200px;
            height: 50px;
            align-items: center;
            .ant-select-selection-item{
                font-size: 16px;
                margin-left: 10px;
            }
        }
    }
    .card{
        margin-top: 20px;
        .card-item{
            display: flex;
            width: 447px;
            border: 2px solid #D8E1FF;
            border-radius: 8px;
            padding: 10px;
            height: 60px;
            margin-bottom: 5px;
            .left{
                display: flex;
                align-items: center;
                gap: 7px;
                img{
                    height: 28px;
                    width: 28px;
                    border-radius: 15px;
                }
            }
            .del-appointment{
                margin-left: auto;
            }
        }
    }
     .ant-form-item-explain-error{
                    width: 150px;
                    font-size: 11px;
                    font-weight: 500;
                }

                .state-multi-select{
                    .ant-select{
                        width: 355px !important;
                    }
                    .ant-select-selector{
                        background-color: #F4F4F4;
                        border-radius: 20px;
                        border: none;
                        width: 350px;
                        min-height: 50px;
                        height: auto;
                        align-items: center;
                        padding: 10px;
                        .ant-select-selection-item{
                            font-size: 16px;
                            margin-left: 10px;
                        }
                    }
                }
                .ant-disabled{
                    cursor: no-drop;
                    .label{
                    color: #b9b7b7;
                    }
                    input{
                        cursor: no-drop;
                    }
                }

                .pgm-top-border{
                    border-top: 1px solid #e0e0e0;
                    padding-top: 15px;
                }
                .promo-error{
                    color: #ff4d4f;
                    margin-left: 0px;
                    font-size: 11px;
                    font-weight: 500;
                }
                .address-search{
                    width: 600px;
                    margin-left: 30px;
                    .ant-select-selector, .ant-select-auto-complete{
                        width: 600px !important;
                    }
                    .ant-select-selection-placeholder{
                        font-weight: 500;
                    }
                    .ant-select-selection-search-input{
                        font-size: 17px;
                        padding-top: 20px !important;
                    }
                    .row{
                        display: flex;
                        gap: 15px;
                    }
                }
}

.program-member{
    .section, .last-section{
        display: flex;
        gap: 60px;
        justify-content: space-between;
        .sub-col{
            width: 50%;
            .ant-picker, .ant-form-item-explain-error, .ant-select{
                width: 100% !important;
                margin-top: 0px !important;
            }
        }
        .last-section{
            .ant-form-item{
                margin-bottom: 0px;
                padding-top: 15px;
            }
        }
        .ant-select-selector{
            font-size: 17px;
            background-color: #F4F4F4;
            height: 50px;
            border: none;
            min-height: 50px;
            height: auto;
            align-items: center;
            padding: 10px;
            .ant-select-selection-item{
                font-size: 16px;
                margin-left: 10px;
            }
        }
    }
    .invitation-link{
        border-top: 1px solid #e0e0e0;
        padding-top: 20px;
    }
}

.program-college{
    .procedure-section{
        display:  block !important;
    }
    .extra-sections{
        margin-top: 20px;
    }
    .college-pricing{
        width: 450px;
        justify-content: space-between;
        display: flex;
        .lbl-name{
            margin-top: 10px;
        }
        .lbl-name-bold{
            margin-top: 15px;
            margin-right: 10px;
        }
        .amount-text{
            align-items: normal !important;
        }
    }
    .business-welcome-text{
        display: block !important;
        .welcome-meesage-info{
            margin-left: auto;
            width: max-content;
            display: block;
            margin-top: 5px;
        }
    }
    .section, .last-section{
        display: flex;
        .sub-col{
            margin-right: 35px;
            .ant-picker, .ant-form-item-explain-error, .ant-select{
                width: 300px !important;
                margin-top: 0px !important;
            }
        }
        .check-label-promo{
            margin-bottom: 0px;
            padding: 0px;
            margin-top: 0px;
        }
        .ant-form-item{
            margin-top: 0px !important;
            width: 100%;
        }
        .last-section{
            .ant-form-item{
                margin-bottom: 0px;
                padding-top: 15px;
            }
            
        }
        .ant-select-selection-placeholder{
            font-size: 17px !important;
            color: #b9b7b7 !important;
        }
        .ant-select-selector{
            font-size: 17px;
            background-color: #F4F4F4;
            height: 50px;
            border: none;
            min-height: 50px;
            height: auto;
            align-items: center;
            padding: 10px;
            .ant-select-selection-item{
                font-size: 16px;
                margin-left: 10px;
            }
        }
    }
    .invitation-link{
        border-top: 1px solid #e0e0e0;
        padding-top: 20px;
    }
}