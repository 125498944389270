@import '../../../../../../shared/styles/colors.scss';


.add-insurance-container__body {

    h3 {

        color: #ad3e06;
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        margin-bottom: 10px;
        margin-top: 0;
    }
}

.add-category-setup-container {
    padding: 30px;

    &__body {
        background: $white-000;
        border-radius: 16px;
        padding: 20px;

        h4 {
            color: $orange-700;
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            margin-bottom: 10px;
            margin-top: 0px;
        }

        &--input-field-wrapper {
            display: flex;
            justify-content: space-between;
            gap: 0px 20px;

            .ant-picker {
                width: 377px;
                height: 48px;
                background: $grey-100;
                border-radius: 16px;
            }

            label {
                color: $grey-1000 !important;
            }
        }

        &--btns-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .add-cat-label {
            display: block;
        }
    }
}